import { ENVIRONMENTS, USER_AGENTS } from '../../constants';

export function isPuppeteerEnv() {
  return process.browser && window.navigator.userAgent === USER_AGENTS.E2E;
}

export function isTestEnv() {
  return process.env.NODE_ENV === ENVIRONMENTS.TEST;
}

export function isStagingEnv() {
  return process.env.NODE_ENV === ENVIRONMENTS.STAGING;
}

export function isProdEnv() {
  return process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION;
}

export function isProdAndNotPuppeteerEnv() {
  return !isPuppeteerEnv() && isProdEnv();
}

export function isDevEnv() {
  return process.env.NODE_ENV === ENVIRONMENTS.DEV;
}

export function isDemoEnv() {
  return process.env.PREFILL_LOGIN_CREDENTIALS === '1' || process.env.PREFILL_LOGIN_CREDENTIALS === 'true';
}

export function isBrowser() {
  return process.browser;
}
