import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Menu, MenuItem } from '@mui/material';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import TextInput from '../TextInput';
import Helpers from '../../../utils/helpers';
import { handleFormError } from '../../../utils/form';
import { useMounted } from '../../../hooks/use-mounted';

const DEFAULTS = {
  value: '',
};
const FIELDS = Object.keys(DEFAULTS);

const validationSchema = Yup.object({
  value: Yup.string(),
});

// TODO: React.memo?
// TODO: Check and improve styles for focus and active [L]
/**
 * @todo: Think about this solution and how to improve this [L]
 */
export default function DropdownMenuInput({
  label, name, onSubmit, value,
}) {
  const anchorRef = useRef(null);
  const [textInput, setTextInput] = useState(null);
  const isMounted = useMounted();

  const [openMenu, setOpenMenu] = useState(false);
  // TODO: useCallback?
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  useEffect(() => {
    if (textInput) {
      textInput.focus();
    }
  }, [textInput]);

  // TODO: useCallback?
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  // TODO: useCallback?
  const handleFormSubmit = (values, helpers) => {
    try {
      onSubmit(values.value, name);
      // eslint-disable-next-line no-use-before-define
      setFieldValue('value', DEFAULTS.value);
    } catch (error) {
      handleFormError({ error, helpers, isMounted });
    }
  };

  const {
    handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue,
  } = useFormik({
    initialValues: Helpers.setValuesByPropList({ value }, DEFAULTS, FIELDS),
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  // TODO: useCallback?
  const handleKeyDown = (e) => {
    // TODO: Move "Enter" to constants and reuse [L]
    if (e.key === 'Enter' && !!values.value) {
      handleSubmit();
    }
  };

  // console.log('DropdownMenuInput render', {
  //   label,
  //   name,
  //   value,
  //   values,
  // }); // TODO: Remove later [L]

  return (
    <>
      <Button
        sx={{
          height: '2rem',
          margin: 0.5,
          backgroundColor: (theme) => theme.palette.background.dropdown,
        }}
        color="inherit"
        endIcon={<ChevronDownIcon fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{ style: { width: 250 } }}
      >
        <MenuItem>
          <TextInput
            onKeyDown={handleKeyDown}
            error={Boolean(touched.value && errors.value)}
            fullWidth
            helperText={touched.value && errors.value}
            label={label}
            name="value"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.value}
            size="small"
            inputRef={setTextInput}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

DropdownMenuInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  value: PropTypes.string,
};

DropdownMenuInput.defaultProps = {
  name: undefined,
  label: undefined,
  onSubmit: (f) => f,
  value: '',
};
