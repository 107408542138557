import PropTypes from 'prop-types';
import Image from 'next/image';
import { styled } from '@mui/material/styles';

// TODO: Improve and refactoring this file. Rename this file to Logo.js [L]
export const LogoBox = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { variant, ...other } = props;
  return <Image src="/static/enneo-logo-box.svg" alt="Enneo logo" width={156} height={156} />;
})``;

LogoBox.defaultProps = {
  variant: 'primary',
};

LogoBox.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
};
