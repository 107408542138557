import { getComparator } from '../sort';

// TODO: Improve, rename and move to utils and reuse? Cover by tests [L]
export const applyFilters = (items, filters) => items.filter((company) => {
  if (filters.query) {
    let queryMatched = false;
    const properties = Object.keys(company);

    properties.forEach((property) => {
      if (company[property].toLowerCase().includes(filters.query.toLowerCase())) {
        queryMatched = true;
      }
    });

    if (!queryMatched) {
      return false;
    }
  }

  return true;
});

// TODO: Improve, rename and move to utils and reuse? Cover by tests [L]
export const applySort = (items, sortValue) => {
  const [sortBy, sortDir] = sortValue.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = items.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

// TODO: Improve, rename and move to utils and reuse? Cover by tests [L]
export const applyPagination = (items, page, rowsPerPage) => items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
