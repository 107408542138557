import { format } from 'date-fns';
import { t } from 'i18next';

// eslint-disable-next-line import/no-cycle
import { getCountryNameByCode } from '../api/country';
import { DEFAULT_CHURN_MODEL_ID, DEFAULT_RULE_ID, USER_ROLE } from '../../constants';
import Utils from '../helpers';
import CustomSelection from '../../components/IncreaseRules/CustomSelection';
import {
  BONUS_OPTIONS,
  COMPARISON_OPTIONS,
  CONSUMPTION_OPTIONS,
  CRITERIA_OPTIONS,
  CRITERIA_VALUES_TO_COLUMN_NAME_MAPPING,
  CURRENT_RULE_OPTIONS,
  GRID_CHANGES_OPTIONS,
  PRICE_TYPE_OPTIONS,
  INCREASE_DATE_OPTIONS,
  INCREASE_LOGIC_OPTIONS,
  INCREASE_LOGIC_VALUES,
  OTHER_COMPONENTS_OPTIONS,
  WHOLESALE_PRICE_ASSUMPTION_OPTIONS,
  BUSINESS_OPTIONS,
} from '../../constants/data';
import { createResourceId } from '../create-resource-id';

// TODO: Deprecated [V1]. Remove?
export function countryListDataToOptions(data) {
  return data.map((item) => ({ label: item.name, value: item.code }));
}

// TODO: Covet by tests [L]
export function accountProfileData(data = {}) {
  const defaultAvatar = 'https://www.irishrsa.ie/wp-content/uploads/2017/03/default-avatar.png';

  // TODO: Update the account profile fields according BE [L]
  const adaptedData = {
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
    email: data.email,
    country: getCountryNameByCode(data.country),
    countryCode: data.country,
    address: data.address,
    zip: data.zip,
    lang: data.lang,

    accessLevel: data.accessLevel,
    kycLevel: data.kycLevel,
    id: data.id,

    avatar: data.image || defaultAvatar,
    requiresAccountDataFill: !data.questionnaire,
    name: `${data.firstName || ''} ${data.lastName || ''}`,
    role: data?.questionnaire?.companyRole ? USER_ROLE.COMPANY : USER_ROLE.EMPLOYEE, // TODO: Use accountType?

    companyName: data.companyName,
    settings: data.settings || {},
  };

  return adaptedData;
}

// TODO: Cover by tests [L]
export function accountProfileDataToBE(data) {
  // TODO: Update the account profile fields according BE [L]
  const adaptedData = {
    firstName: data.firstName,
    lastName: data.lastName,
    gender: data.gender,
    lang: data.lang,
    country: data.country,
    zip: data.zip,
    address: data.address,
    questionnaire: {
      referencedFrom: data.referencedFrom,
      accountType: data.accountType,
      policy: data.policy,
      experience: data.experience,
      isTaxedByUSA: data.isTaxedByUSA,
      companyRole: data.companyRole,
      website: data.website,
      employeesCount: data.employeesCount,
    },
  };

  return adaptedData;
}

// TODO: Cover by tests [L]
// TODO: Improve this adapter, move not needed here data to page and components, make it simpler and clear. [L]
export function effectPredictions({ calculations = {} } = {}, options = {}) {
  const historyAbsPrice = Number(calculations.historyBasePrice)
    + (Number(calculations.consumption) * Number(calculations.historyWorkingPrice)) / 100;
  const adjustedAbsPrice = Number(calculations.adjustedBasePrice)
    + (Number(calculations.consumption) * Number(calculations.adjustedWorkingPrice)) / 100;
  const contracts = Number(calculations.contracts);

  // TODO: [Translation] language switch in component works not instantly [L]
  return [
    {
      title: `${t('Price effect (gross)')}`,
      isProcessing: Number(calculations.pending) > 0,
      contracts,
      valid: contracts - calculations.pending,
      data: [
        {
          label: `${t('AP/kWh')}`,
          currentValue: Number(calculations.historyWorkingPrice).toFixed(2),
          changeImpact: (
            ((Number(calculations.adjustedWorkingPrice) - Number(calculations.historyWorkingPrice))
              / Number(calculations.historyWorkingPrice))
              * 100 || 0
          ).toFixed(1),
          newValue: Number(calculations.adjustedWorkingPrice).toFixed(2),
          unit: '¢',
          identifyEffectColor: Utils.getColorByIncrease,
        },
        {
          label: `${t('GP/month')}`,
          currentValue: Number(calculations.historyBasePrice / 12.0).toFixed(2),
          changeImpact: (Number(calculations.adjustedBasePrice) - Number(calculations.historyBasePrice)).toFixed(1),
          newValue: Number(calculations.adjustedBasePrice / 12.0).toFixed(2),
          unit: `${t('€')}`,
          identifyEffectColor: Utils.getColorByIncrease, // TODO: Use identifyEffectColor methods directly? [L]
        },
        {
          label: `${t('Total/year')}:`,
          currentValue: historyAbsPrice.toFixed(0),
          changeImpact: ((adjustedAbsPrice * 100) / historyAbsPrice - 100 || 0).toFixed(1),
          newValue: adjustedAbsPrice.toFixed(0),
          unit: `${t('€')}`,
          identifyEffectColor: Utils.getColorByIncrease,
        },
      ],
    },
    {
      title: `${t('Margin effect (net)')}`,
      isProcessing: Number(calculations.pending) > 0,
      contracts,
      valid: contracts - calculations.pending,
      data: [
        {
          label: `${t('Margin')} (%):`,
          currentValue: Number(calculations.historyMarginRel).toFixed(1),
          changeImpact: (Number(calculations.adjustedMarginRel) - Number(calculations.historyMarginRel)).toFixed(2),
          newValue: Number(calculations.adjustedMarginRel).toFixed(1),
          unit: '%',
          identifyEffectColor: Utils.getColorByMargin,
          individualSeverityPill: true,
          hideSeverityPill: true,
        },
        {
          label: `${t('Margin')} (€):`,
          currentValue: Number(calculations.historyMarginAbsThisYear).toFixed(0),
          newValue: Number(calculations.adjustedMarginAbsThisYear).toFixed(0),
          unit: '€',
          identifyEffectColor: Utils.getColorByAbsMargin,
          individualSeverityPill: true,
          hideSeverityPill: true,
        },
      ],
    },
    {
      title: t('Churn prediction'),
      isProcessing: Number(calculations.pending) > 0,
      contracts,
      valid: contracts - calculations.pending,
      data: [
        {
          label: `${t('Contracts')}:`,
          currentValue: contracts.toFixed(0),
          changeImpact: (Number(calculations.adjustedChurn) * 100).toFixed(0),
          newValue: (contracts * (1 - Number(calculations.adjustedChurn))).toFixed(1),
          unit: '',
          identifyEffectColor: Utils.getColorByChurn,
        },
        {
          label: `${t('Gross Margin')} (${t('net')}):`,
          currentValue: Number(calculations.historyMarginAbsThisYear * contracts).toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          changeImpact: (
            Number(
              ((calculations.adjustedMarginAbsThisYear * (contracts * (1 - Number(calculations.adjustedChurn)))
                - calculations.historyMarginAbsThisYear * contracts)
                / (Math.abs(calculations.historyMarginAbsThisYear) * contracts))
                * 100,
            ) || 0
          ).toFixed(1),
          newValue: Number(
            calculations.adjustedMarginAbsThisYear * (contracts * (1 - Number(calculations.adjustedChurn))),
          ).toLocaleString('en-US', { maximumFractionDigits: 0 }),
          unit: '€',
          identifyEffectColor: Utils.getColorByTotalRevChange,
        },
        {
          label: `${t('Revenue')} (${t('net')}):`,
          currentValue: Number(historyAbsPrice * contracts).toLocaleString('en-US', { maximumFractionDigits: 0 }),
          changeImpact:
            (
              Number(
                ((adjustedAbsPrice * (contracts * (1 - Number(calculations.adjustedChurn)))
                  - historyAbsPrice * contracts)
                  / (historyAbsPrice * contracts))
                  * 100,
              ) || 0
            ).toFixed(1) || 0,
          newValue: Number(adjustedAbsPrice * (contracts * (1 - Number(calculations.adjustedChurn)))).toLocaleString(
            'en-US',
            { maximumFractionDigits: 0 },
          ),
          unit: '€',
          identifyEffectColor: Utils.getColorByTotalRevChange,
        },
      ],
      modelOptions: options?.churnModelsOptions,
    },
  ];
}

export function adaptDictionary(dictionary) {
  return {
    rule: {
      title: 'Rule',
      options: dictionary.rules.map((item) => ({ id: item.ruleId, title: item.name, data: item })),
    },
    product: {
      title: 'Product',
      options: dictionary.products.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    campaign: {
      title: 'Campaign',
      options: dictionary.campaigns.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    energySubTypeId: {
      // TODO: Better energySubtype
      title: 'Energy subtype',
      options: dictionary.energySubtypes.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    salesChannelId: {
      // TODO: Better salesChannel
      title: 'Sales channel',
      options: dictionary.salesChannels.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    energyType: {
      title: 'Energy type',
      options: dictionary.energyTypes.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    margin: {
      title: 'Margin',
      options: dictionary.margins.map((item) => ({
        id: item.id,
        title: item.data.title,
        data: item.data,
      })),
    },
    churnModel: {
      title: 'Churn model',
      options: dictionary.churnModels.map((item) => ({
        id: item.id,
        title: item.name,
        data: item,
      })),
    },
    consumption: {
      title: 'Consumption',
      options: dictionary.consumptions.map((item) => ({
        id: item.id,
        title: item.label,
        data: item,
      })),
    },
    priceType: {
      title: 'Price type',
      options: dictionary.priceTypes.map((item) => ({
        id: item.id,
        title: item.label,
        data: item,
      })),
    },
    bonus: {
      title: 'Bonus',
      options: dictionary.bonusOptions.map((item) => ({
        id: item.id,
        title: item.label,
        data: item,
      })),
    },
    business: {
      title: 'Business',
      options: dictionary.businessOptions.map((item) => ({
        id: item.id,
        title: item.label,
        data: item,
      })),
    },
    contract: {
      title: 'Contract',
      options: [],
    },
  };
}

// TODO: Cover by tests [L]
export function getOptions(dictionaries = {}) {
  return {
    increaseLogicOptions: INCREASE_LOGIC_OPTIONS,
    wholesalePriceAssumptionOptions: WHOLESALE_PRICE_ASSUMPTION_OPTIONS,
    gridChanges: GRID_CHANGES_OPTIONS,
    componentsOptions: OTHER_COMPONENTS_OPTIONS,
    comparisonOptions: COMPARISON_OPTIONS,
    increaseDateOptions: INCREASE_DATE_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.INCREASE_DATE,
    })),
    consumptionOptions: CONSUMPTION_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.CONSUMPTION,
    })),
    priceTypeOptions: PRICE_TYPE_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.PRICE_TYPE,
    })),
    bonusOptions: BONUS_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.BONUS,
    })),
    businessOptions: BUSINESS_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.BUSINESS,
    })),
    currentRuleOptions: CURRENT_RULE_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.CURRENT_RULE,
    })),
    criteriaOptions: CRITERIA_OPTIONS.map((item) => ({
      ...item,
      group: CustomSelection.GROUP_NAMES.CRITERIA,
      columnName: CRITERIA_VALUES_TO_COLUMN_NAME_MAPPING[item.value],
    })),

    churnModelsOptions: dictionaries.churnModels?.map((item) => ({ id: item.id, value: item.id, label: item.name })),
    ruleOptions: dictionaries.rules.map((item) => ({ id: item.ruleId, value: item.ruleId, label: item.name })),
    channelOptions: dictionaries.salesChannels?.map((item) => ({
      id: item.name,
      key: 'salesChannelId',
      label: item.name,
      value: item.id,
      group: CustomSelection.GROUP_NAMES.CHANNEL,
    })),
    productOptions: dictionaries.products.map((item) => ({
      id: item.name,
      key: 'productId',
      label: item.name,
      value: item.id,
      group: CustomSelection.GROUP_NAMES.PRODUCT,
    })),
    energyTypes: dictionaries.energyTypes.map((item) => ({
      id: item.name,
      key: 'energyTypeId',
      value: item.id,
      label: item.name,
      group: CustomSelection.GROUP_NAMES.ENERGY_TYPE,
    })),
    energySubtypes: dictionaries.energySubtypes.map((item) => ({
      id: item.code, // TODO: Should be used id, not a code?
      key: 'energySubTypeId',
      value: item.id,
      label: item.name,
      group: CustomSelection.GROUP_NAMES.ENERGY_SUBTYPE_ID,
    })),
    campaigns: dictionaries.campaigns.map((item) => ({
      id: item.name, // TODO: Should be used id, not a name?
      key: 'campaignId',
      value: item.id,
      label: item.name,
      group: CustomSelection.GROUP_NAMES.CAMPAIGN,
    })),
    marginOptions: dictionaries.margins.map(({ data = {} } = {}) => ({
      id: data.title,
      key: 'margin',
      label: data.title,
      value: data.value,
      comparator: data.comparator,
      from: data.from,
      to: data.to,
      group: CustomSelection.GROUP_NAMES.MARGIN,
    })),
  };
}

// TODO: Cover by tests [L]
export function priceAdjustmentParams(params = {}) {
  const { filterBy = [], groupBy = [] } = params;

  const adaptedParams = {
    groups: groupBy.map((item) => item.value),
    filters: filterBy.map((item) => ({
      key: item.key,
      value: item.value,
      from: item.from,
      to: item.to,
      comparator: item.comparator,
    })),
  };

  return adaptedParams;
}

// TODO: Cover by tests [L]
export function updatePriceAdjustmentParams(params = {}) {
  const { item = {}, options = {} } = params;

  const priceAdjustmentRuleId = options.ruleOptions?.find((rule) => rule.value === item.increaseRule)?.id || null;
  const { groups, filters } = priceAdjustmentParams(params);

  return {
    groups,
    filters,
    priceAdjustmentRule: priceAdjustmentRuleId,
    churnModelId: item.churnModelId,
    item: item?.original,
  };
}

// TODO: Cover by tests [L]
export function priceAdjustmentData({ segments: data = [] } = {}, optionsData = {}) {
  // FIXME: Move the total calculations to separate method [L]
  let totalContracts = 0;
  let totalContributionMargin = 0;
  data?.forEach((item) => {
    totalContracts += Number(item.contracts || 0);
    totalContributionMargin += Number(item.contributionMarginYr1 || 0);
  });

  const adaptedData = data?.map((item) => {
    const historyAbsPrice = Number(item.historyBasePrice) + (Number(item.consumption) * Number(item.historyWorkingPrice)) / 100;
    const adjustedAbsPrice = Number(item.adjustedBasePrice) + (Number(item.consumption) * Number(item.adjustedWorkingPrice)) / 100;

    const ruleId = item.ruleId || DEFAULT_RULE_ID;
    const churnModelId = item.churnModelId || DEFAULT_CHURN_MODEL_ID;

    return {
      original: item,

      // TODO: Improve the prop names, make it more correct [L]
      id: `${item.productId}-${item.initialDuration}-${item.salesChannelName}-${item.energyTypeId}-${item.energySubTypeId}-${item.bonus}-${item.campaignId}-${item.priceType}-${item.consumptionRange}`,
      productId: item.productId,
      productName: item.productName,
      salesChannelName: item.salesChannelName,
      energySubType: optionsData?.energySubtypes?.find((e) => Number(e.value) === Number(item.energySubTypeId))?.label, // TODO: Should be equal id, not a value?
      bonus: item.bonus ? 'Yes' : 'No',
      business: item.business ? 'Yes' : 'No',
      campaign: optionsData?.campaigns?.find((c) => Number(c.value) === Number(item.campaignId))?.label,
      priceType: item.priceType,
      consumptionRange: item.consumptionRange,
      runtime: item.initialDuration || 0,
      contractsCount: item.contracts,
      contractsProgress: (item.contracts / totalContracts) * 100,
      marginYr1: item.historyMarginRel,
      increaseRule: optionsData?.ruleOptions?.find((r) => Number(r.id) === Number(ruleId))?.value,
      churnModelId: optionsData?.churnModelsOptions?.find((c) => Number(c.id) === Number(churnModelId))?.value,
      contributionMarginYr1: item.historyMarginAbsThisYear,
      contributionMarginYr1Progress: Math.min(
        Math.abs(item.historyMarginAbsThisYear / totalContributionMargin) * 100,
        100,
      ),
      churnInitial: (item.historyChurn / item.contracts) * 100,
      isProcessing: Number(item.pending) > 0,
      newMarginRel: item.adjustedMarginRel,
      newIncrease: ((adjustedAbsPrice * 100) / historyAbsPrice - 100).toFixed(2),
      energyTypeName: optionsData?.energyTypes?.find((et) => et.value === item.energyTypeId)?.value,
      processedContracts: item.contracts - item.pending || 0,
    };
  });

  return { items: adaptedData || [], totalContracts, totalContributionMargin };
}

export function contractToPriceAdjustment(contract, options) {
  return {
    id: contract.id,
    contractId: contract.contractId,
    salesChannelName: contract.salesChannelName,
    productName: contract.productName,
    expectedChurn: contract.expectedChurn,
    status: contract.status,
    energyTypeId: contract.energySubTypeId,
    transferToBillingSystemDate: contract.transferDate,
    priceAdjustmentEffectiveDate: contract.effectiveDate,
    priceAdjustmentRuleName: options.ruleOptions?.find((option) => option.id === contract.ruleId)?.label,
    priceAdjustmentRuleId: contract.ruleId,
    currentWorkingPrice: contract.currentAPGross,
    currentBasePrice: contract.currentGPGross,
    adjustedWorkingPrice: contract.newAPGross,
    adjustedBasePrice: contract.newGPGross,
    adjustedMarginRel: contract.newMargin,
    adjustedPriceIncrease: contract.increase,
    failureReason: contract.failureReason,
    isApproved: !!contract.approved,
    outcome: contract.outcome,
  };
}

// TODO: Cover by tests [L]
export function dashboardProducts(data = {}) {
  const colors = [
    // TODO: Add more and move to constants [L]
    '#72E2A6',
    '#6891F5',
    '#9F6AF5',
    '#D185D5',
    '#FF874A',
    '#FFDC37',
    // '#FFB547',
    // '#F06191',
    // '#64B6F7',
    // '#9DA4DD',
    // '#7783DB',
    // '#0C7CD5',
    // '#455a64',
    // '#2F3EB1',
    // 'rgba(86, 100, 210, 0.5)',
  ];

  const products = data?.products || [];

  const adaptedProducts = products.map((item, index) => ({
    id: createResourceId(),
    color: colors[index],
    label: item.name,
    count: item.contracts,
  }));

  return adaptedProducts;
}

// TODO: Cover by tests [L]
export function dashboardOverview(data = {}) {
  const { newIn24Hours = [], stats = [] } = data;
  const [newIn24HoursLastMonth = {}] = newIn24Hours;
  const statsLastMonth = stats[stats.length - 1] || {};
  const statsPrevMonth = stats[stats.length - 2] || {};

  const adaptedData = {
    grossMargin: statsPrevMonth.grossMargin,
    prevYearGrossMargin: stats[stats.length - 14]?.grossMargin,
    grossMarginValuesHistory: stats.map((item) => item.grossMargin),
    grossMarginLabelsHistory: stats.map((item) => item.month),
    costs: statsPrevMonth.costs,
    prevYearCosts: stats[stats.length - 14]?.costs,
    costsValuesHistory: stats.map((item) => item.costs),
    costsLabelsHistory: stats.map((item) => item.month),
    // TODO: [Translate] months [L]
    currentMonthName: format(new Date(statsPrevMonth.month), 'LLLL'),
    revenue: statsPrevMonth.revenue,
    prevYearRevenue: stats[stats.length - 14]?.revenue,
    revenueValuesHistory: stats.map((item) => item.revenue),
    revenueLabelsHistory: stats.map((item) => item.month),
    newContractsCount: newIn24HoursLastMonth.newIn24Hours,
    newContractsCountValuesHistory: newIn24Hours.map((item) => item.newIn24Hours),
    suppliedContractsCount: statsLastMonth.supplied,
    suppliedContractsCountValuesHistory: stats.map((item) => item.supplied),
    suppliedContractsRelation: (statsLastMonth.supplied / statsPrevMonth.supplied) * 100,
    suppliedAndPendingContractsCount: statsLastMonth.suppliedAndPending,
    suppliedAndPendingContractsCountValuesHistory: stats.map((item) => item.suppliedAndPending),
    suppliedAndPendingContractsRelation: (statsLastMonth.suppliedAndPending / statsPrevMonth.suppliedAndPending) * 100,
  };

  return adaptedData;
}

// TODO: Cover by tests [L]
export function dashboardSalesChannels(data = {}) {
  const salesChannels = data.salesChannels || [];

  const groupData = salesChannels.reduce((result, item) => {
    if (!result[item.salesChannel]) {
      // eslint-disable-next-line no-param-reassign
      result[item.salesChannel] = [];
    }
    result[item.salesChannel].push(item.contracts);

    return result;
  }, {});

  const series = Object.keys(groupData).reduce((result, name) => {
    result.push({
      name,
      data: groupData[name],
    });
    return result;
  }, []);

  const categories = salesChannels.reduce((result, item) => {
    if (!result.includes(item.period)) {
      result.push(item.period);
    }

    return result;
  }, []);

  return { series, categories };
}

// TODO: Cover by tests [L]
export function currentRuleConfigs(serverRuleData) {
  const ruleData = serverRuleData?.rule || {};
  const data = ruleData?.data || {};
  const specification = ruleData?.data?.specification || {};

  const adaptedData = {
    ...ruleData,
    ...data,
    ...specification,
  };

  delete adaptedData.data;
  delete adaptedData.specification;

  return adaptedData;
}

// TODO: Cover by tests [L]
export function increaseRuleConfigToBE(data) {
  const specifications = {
    [INCREASE_LOGIC_VALUES.RULE_BASED]: {
      passOnGridMode: data.passOnGridMode,
      neverIncreaseAbove: data.neverIncreaseAbove, // FIXME: isNeverIncreaseAbove
      neverIncreaseAboveMargin: data.neverIncreaseAboveMargin,
      neverIncreaseAboveAbs: data.neverIncreaseAboveAbs, // FIXME: isNeverIncreaseAboveAbs
      neverIncreaseAboveMarginAbs: data.neverIncreaseAboveMarginAbs,
      passOnGrid: data.passOnGrid, // FIXME: isPassOnGrid
      passOnOtherComponents: data.passOnOtherComponents, // FIXME: isPassOnOtherComponents
      passOnOtherComponentsMode: data.passOnOtherComponentsMode,
      energyPriceRules: data.energyPriceRules,
    },
    [INCREASE_LOGIC_VALUES.REACH_BREAK_EVEN]: {
      considerCtA: data.considerCtA, // FIXME: isConsiderCtA
      considerCtS: data.considerCtS, // FIXME: isConsiderCtS
      breakEvenYear: data.breakEvenYear,
      considerBonus: data.considerBonus, // FIXME: isConsiderBonus
      recoverLossesOfChurnedCustomers: data.recoverLossesOfChurnedCustomers, // FIXME: isRecoverLossesOfChurnedCustomers
    },
    [INCREASE_LOGIC_VALUES.DO_NOT_INCREASE]: {},
    [INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN]: {
      basePriceMargin: data.basePriceMargin,
      workingPriceMargin: data.workingPriceMargin,
      neverIncreaseAbove: data.neverIncreaseAbove,
      neverIncreaseAboveMargin: data.neverIncreaseAboveMargin,
      neverIncreaseAboveAbs: data.neverIncreaseAboveAbs,
      neverIncreaseAboveMarginAbs: data.neverIncreaseAboveMarginAbs,
      workingPriceMarginYearAbs: data.workingPriceMarginYearAbs,
      maxWorkingPriceMarginAbsGross: data.maxWorkingPriceMarginAbsGross,
    },
    [INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN_AT_FIXED_BASE_PRICE]: {
      overallFixedMargin: data.overallFixedMargin,
      overallVariableMargin: data.overallVariableMargin,
      maxWorkingPriceAbsGross: data.maxWorkingPriceAbsGross,
      neverIncreaseAbove: data.neverIncreaseAbove,
      neverIncreaseAboveMargin: data.neverIncreaseAboveMargin,
      neverIncreaseAboveAbs: data.neverIncreaseAboveAbs,
      neverIncreaseAboveMarginAbs: data.neverIncreaseAboveMarginAbs,
    },
  };

  const adaptedData = {
    name: data.name,
    status: data.status,

    data: {
      increaseLogic: data.increaseLogic,
      wholesalePriceAssumption: data.wholesalePriceAssumption,
      filters: data.filters,
      specification: specifications[data.increaseLogic],
      churnModel: data.churnModel,
    },
  };

  return adaptedData;
}

export function prepareRuleSetsForBe(dictionary, ruleSets = []) {
  const sets = [...ruleSets].reverse();
  return sets.map((set, index) => ({
    ...set,
    orderId: index + 1,
    filters: set.filters?.map((filter) => {
      const option = dictionary[filter.key].options.find((opt) => opt.id === filter.value);
      const { id, value, ...optionData } = option?.data ?? {};

      return {
        value: filter.key === 'margin' ? value : value ?? option?.id ?? filter.value,
        ...optionData,
        key: filter.key,
        id: filter.id,
      };
    }),
  }));
}
