import { Box, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

export default function InputErrorMessage({ error, sx }) {
  return (
    Boolean(error) && (
      <Box sx={sx}>
        <FormHelperText error>{error}</FormHelperText>
      </Box>
    )
  );
}

InputErrorMessage.propTypes = {
  error: PropTypes.string,
  sx: PropTypes.shape({}),
};

InputErrorMessage.defaultProps = {
  error: undefined,
  sx: undefined,
};
