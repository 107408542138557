// Colors

import { uiColours } from './base-theme-options';

const uiSidebar = {
  menuButton: {
    default: {
      background: 'transparent',
      color: uiColours.slate.darker,
    },
    hover: {
      background: uiColours.blue.lightest,
    },
    active: {
      background: uiColours.blue.medium,
      color: uiColours.slate.dark,
    },
    disabled: {
      color: uiColours.slate.light,
    },
  },
};

const brandColors = {
  purple: '#9F6AF5',
  blue: '#6891F5',
  green: '#72E2A6',
  indigo: '#150B30',
  darkGrey: '#A9ACB1',
  lightGrey: '#EDEBEF',
};

const neutral = {
  100: '#F3F4F6', // table headers
  200: '#E5E7EB',
  300: '#D0CED6', // menu item text
  400: '#9CA3AF', // menu item icons
  500: '#6B7280', // menu item headlines
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: brandColors.indigo,
};

const background = {
  default: brandColors.lightGrey, // background of right side
  paper: '#FFFFFF',
  dropdown: brandColors.lightGrey,
};

const formControls = {
  background: '#EDEBEF',
};

const divider = '#EDEBEF';

const primary = {
  main: uiColours.blue.dark,
  light: uiColours.blue.medium,
  dark: uiColours.blue.primary,
  contrastText: '#FFFFFF',
};

const secondary = {
  main: uiColours.blue.primary,
  light: '#3FC79A',
  dark: '#0B815A',
  contrastText: '#FFFFFF',
};

const success = {
  main: '#72E2A6',
  light: '#C7F3DB',
  dark: '#0E8074',
  contrastText: '#1A132D',
};

const info = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#FFFFFF',
};

const warning = {
  main: '#FFDC37',
  light: '#FFF1AF',
  dark: '#B27B16',
  contrastText: '#150B30',
};

const error = {
  main: '#FF5F66',
  light: '#FFBFC2',
  dark: '#922E2E',
  contrastText: '#FFFFFF',
};

const text = {
  primary: uiColours.slate.primary,
  secondary: uiColours.slate.darker,
  disabled: 'rgba(55, 65, 81, 0.48)',
};

const sidebar = {
  background: '#ffffff',
  menuItemOutline: '#f8f8f8',
  menuItemTextColor: '#6B7280',
  menuItemActiveColor: '#1ac569',
};

export const lightThemeOptions = {
  colours: {
    primaryBg: uiColours.grey.lightest,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: '#FFFFFF',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: brandColors.lightGrey,
          '&.MuiChip-filledDefault': {
            backgroundColor: brandColors.lightGrey,
            '& .MuiChip-deleteIcon': {
              color: neutral[400],
            },
          },
          '&.MuiChip-outlinedDefault': {
            '& .MuiChip-deleteIcon': {
              color: neutral[300],
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: formControls.background,
        },
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1,
        },
        list: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          '.MuiTableCell-root': {
            color: neutral[700],
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: text.secondary,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: uiColours.grey.darker,
        },
        bar: {
          backgroundColor: uiColours.blue.darker,
        },
      },
    },
  },
  typography: {
    h3: {
      color: text.secondary,
    },
    h4: {
      color: brandColors.indigo,
    },
  },
  palette: {
    action: {
      active: neutral[500],
      focus: 'rgba(55, 65, 81, 0.12)',
      hover: uiColours.blue.lightest,
      selected: 'rgba(55, 65, 81, 0.08)',
      disabledBackground: 'rgba(55, 65, 81, 0.12)',
      disabled: 'rgba(55, 65, 81, 0.26)',
    },
    background,
    sidebar,
    formControls,
    divider,
    error,
    info,
    mode: 'light',
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
    uiSidebar,
  },
  shadows: [
    'none',
    '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
    '0px 1px 2px rgba(100, 116, 139, 0.12)',
    '0px 1px 4px rgba(100, 116, 139, 0.12)',
    '0px 1px 5px rgba(100, 116, 139, 0.12)',
    '0px 1px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 6px rgba(100, 116, 139, 0.12)',
    '0px 3px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
    '0px 5px 12px rgba(100, 116, 139, 0.12)',
    '0px 5px 14px rgba(100, 116, 139, 0.12)',
    '0px 5px 15px rgba(100, 116, 139, 0.12)',
    '0px 6px 15px rgba(100, 116, 139, 0.12)',
    '0px 7px 15px rgba(100, 116, 139, 0.12)',
    '0px 8px 15px rgba(100, 116, 139, 0.12)',
    '0px 9px 15px rgba(100, 116, 139, 0.12)',
    '0px 10px 15px rgba(100, 116, 139, 0.12)',
    '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
    '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
  ],
};
