// eslint-disable-next-line import/no-cycle
import useLocalStorage from './useLocalStorage';

export const LOCAL_STORAGE_KEYS = {
  SIGN_UP_WIZARD_FORM: 'SignUpWizardForm',
  PAGE_OPTIONS: 'pageOptions',
  IS_MENU_COLLAPSED: 'isMenuCollapsed',
  GROUP_BY_VALUES: 'GROUP_BY_VALUES',
  FILTER_BY_VALUES: 'FILTER_BY_VALUES',
};

const WARNING_MESSAGE = '"localStorage" is blocked or not supported. Please check your browser settings, or contact us.';

function getValue(key) {
  try {
    if (!process.browser || !key) {
      return null;
    }

    return window.localStorage.getItem(key);
  } catch (error) {
    console.warn(WARNING_MESSAGE, error);
    return null;
  }
}

function setValue(key, value) {
  try {
    if (!process.browser || !key) {
      return;
    }

    window.localStorage.setItem(key, value);
  } catch (error) {
    console.warn(WARNING_MESSAGE, error);
  }
}

function setValueAsync(key, value) {
  return Promise.resolve().then(() => setValue(key, value));
}

function getValueAsync(key) {
  return Promise.resolve().then(() => getValue(key));
}

function setData(key, data) {
  try {
    setValue(key, JSON.stringify(data));
  } catch (error) {
    console.warn(error);
  }
}

function getData(key) {
  try {
    return JSON.parse(getValue(key));
  } catch (error) {
    console.warn(error);
    return null;
  }
}

function clearData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.warn(error);
  }
}

// TODO: Cover by tests [L]
const LocalStorage = {
  setData,
  getData,
  clearData,
  getValue,
  setValue,
  setValueAsync,
  getValueAsync,
  useLocalStorage,
};

LocalStorage.KEYS = LOCAL_STORAGE_KEYS;

export default LocalStorage;
