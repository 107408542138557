import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import InputErrorMessage from '../InputErrorMessage';

// TODO: Make the component more universal?
export default function Dropdown({
  label,
  options,
  name,
  value,
  error,
  onChange,
  onBlur,
  sx,
  variant,
  disabled,
  fullWidth,
  size,
}) {
  return (
    <FormControl sx={sx} fullWidth={fullWidth} variant={variant} size={size}>
      <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
      <Select
        label={label}
        error={Boolean(error)}
        labelId={`select-${name}-label`}
        id={`select-${name}-label`}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <InputErrorMessage error={error} />
    </FormControl>
  );
}

Dropdown.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sx: PropTypes.shape({}),
};

Dropdown.defaultProps = {
  error: undefined,
  options: [],
  label: undefined,
  value: '',
  sx: undefined,
  disabled: undefined,
  fullWidth: undefined,
  size: undefined,
  variant: 'outlined',
  onChange: (f) => f,
  onBlur: (f) => f,
};
