import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'next/router';
import * as AuthAPI from '../utils/api/auth';
import { isVerificationPage } from '../utils/helpers/location';
import LocalStorage from '../utils/localStorage';
import { PAGE_URLS } from '../constants';

const shouldRedirectToVerifyPage = (data) => data.requiresDeviceVerification && !isVerificationPage();

const ActionType = {
  INITIALIZE: 'INITIALIZE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
};

const PLATFORM = 'basic/auth0';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user = null } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const AuthContext = createContext({
  ...initialState,
  platform: PLATFORM,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  me: () => Promise.resolve(),
});

export function AuthProvider(props) {
  const { children } = props;
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [, , clearPageOptionsData] = LocalStorage.useLocalStorage(LocalStorage.KEYS.PAGE_OPTIONS);

  const me = async () => {
    try {
      const meResult = await AuthAPI.me();

      if (!meResult.isAuthenticated) {
        throw new Error('User is not authenticated');
      }

      const user = await AuthAPI.getProfile();

      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => me(), []);

  const login = async (email, password, { redirectURL }) => {
    const loginResult = await AuthAPI.login({ email, password });

    if (shouldRedirectToVerifyPage(loginResult)) {
      router.push(PAGE_URLS.VERIFICATION).catch(console.error);
      return;
    }

    const user = await AuthAPI.getProfile();
    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user,
      },
    });

    if (redirectURL) {
      router.push(redirectURL).catch(console.error);
    }
  };

  const logout = async () => {
    await AuthAPI.logout();
    dispatch({ type: ActionType.LOGOUT });
    clearPageOptionsData();
  };

  const register = async (email, password, { redirectURL }) => {
    const registerResult = await AuthAPI.register({ email, password });

    dispatch({
      type: ActionType.REGISTER,
      payload: {},
    });

    if (shouldRedirectToVerifyPage(registerResult)) {
      router.push(PAGE_URLS.VERIFICATION).catch(console.error);
      return;
    }
    if (redirectURL) {
      router.push(redirectURL).catch(console.error);
    }
  };

  return (
    <AuthContext.Provider
      value={
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        {
          // TODO: Use memo
          ...state,
          platform: PLATFORM,
          login,
          logout,
          register,
          me,
        }
      }
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
