export const { HOME_PAGE } = process.env;
export const SITE_URL = `${process.env.SCHEMA}://${process.env.DOMAIN}`;
export const BACKEND_API = SITE_URL;

export const ENVIRONMENTS = {
  TEST: 'test',
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEV: 'development',
};

export const USER_AGENTS = {
  E2E: 'ltn-puppeteer',
};

export const PAGE_URLS = {
  HOME: '/',

  LOGIN: '/authentication/login',
  PASSWORD_RECOVERY: '/authentication/password-recovery',
  PASSWORD_RECOVERY_SET: '/authentication/password-recovery/set',
  VERIFICATION: '/authentication/verify',

  CONTRACT_PERFORMANCE: '/contract-performance',
  CHURN_PROFILE: '/churn-profiles',
  WHOLESALE_PRICES: '/wholesale-prices',
  INCREASE_RULES: '/increase-rules',

  OUTPUT_MANAGEMENT_PLACEHOLDER: '/opm',

  MIND: {
    TICKET: '/mind/ticket',
    LABEL: '/mind/label',
    TRY: '/mind/try',
    BENCHMARK: '/mind/benchmark',
  },

  PRICE_ADJUSTMENTS: '/price-adjustments',
  EXECUTE: '/price-adjustments/execute',
  RULES_MAPPING: '/price-adjustments/rules-mapping',
  ASSUMPTIONS: '/price-adjustments/assumptions',

  SETTINGS: '/settings',
  CONFIGURATIONS: '/configurations',

  PROFILE_DETAILS: '/profile/details',
  PROFILE_EDIT: '/profile/edit',

  RULES: '/increase-rules',
};

export const THIRD_PARTY_API = {
  GOOGLE_AUTH: `${BACKEND_API}/api/auth/v1/google/login`,
  MICROSOFT_AUTH: `${BACKEND_API}/api/auth/v1/microsoft/login`,
};

export const PAGE_TITLE = 'Enneo';

export const HTTP_CODES = {
  UNAUTHORIZED: 401,
};

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

// TODO: Deprecated [V1]. Remove after sign up will be adapted
export const USER_ROLE = {
  COMPANY: 'company',
  EMPLOYEE: 'employee',
  TRADER: 'trader',
};

export const DEV_DEMO_CREDENTIALS = {
  EMAIL: 'demo@enneo.dev',
  PASSWORD: 'demo1234',
};

export const DEFAULT_RULE_ID = 0; // NEVER_INCREASE_RULE_ID
export const DEFAULT_CHURN_MODEL_ID = 1;

export const MENU_SECTIONS = {
  ALL: 'all',
  DASHBOARD: 'dashboard',
  PRICE: 'price',
  SEND: 'send',
  MIND: 'mind',
};

export const ENERGY_TYPES = {
  ELECTRICITY: {
    id: '1',
    name: 'Electricity',
  },
  GAS: {
    id: '2',
    name: 'Gas',
  },
};

export const SORT_VALUE = {
  ASC: 'asc',
  DESC: 'desc',
};

export const RULE_STATE = {
  ACTIVE: 'active',
  DRAFT: 'draft',
};

export const DATE_FORMAT = 'yyyy-MM-dd';

export const ALIGNS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const BREAKPOINTS = {
  MD: 'md',
};
