import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  TableSortLabel,
  Chip,
  Box,
  Typography,
  LinearProgress,
  Avatar,
} from '@mui/material';
import { useState } from 'react';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { Scrollbar } from '../../Scrollbar';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { SeverityPill } from '../../SeverityPill';
import Utils from '../../../utils/helpers';
import Dropdown from '../../__common__/Dropdown';
import { InformationCircleOutlined as InformationCircleOutlinedIcon } from '../../../icons/information-circle-outlined';

import { applyPagination, applySort } from '../../../utils/helpers/table/helpers';
import Loader from '../../Loader';

// TODO: Improve this [L]
const DESCRIPTION = {
  marginYr1: 'description_margin2',
  contributionMarginYr1: 'description_contributionMargin2',
  churnInitial: 'description_churnInitial',
};

// TODO: Move to constants and reuse? [L]
const SALES_CHANNEL_NAMES = {
  VERIVOX: 'Verivox',
  CHECK24: 'Check24',
};

const COLUMN_NAMES = {
  SALES_CHANNEL: 'salesChannelName',
  PRODUCT: 'productName',
  ENERGY_TYPE: 'energyTypeName',
  RUNTIME: 'runtime',
  ENERGY_SUBTYPE: 'energySubType',
  BONUS: 'bonus',
  BUSINESS: 'business',
  CAMPAIGN: 'campaign',
  PRICE_TYPE: 'priceType',
  CONSUMPTION_RANGE: 'consumptionRange',
  CONTRACTS: 'contractsCount',
  MARGIN: 'marginYr1',
  CONTRIBUTION_MARGIN: 'contributionMarginYr1',
  CHURN: 'churnInitial',
  INCREASE_RULE: 'increaseRule',
  CHURN_MODEL: 'churnModelId',
  OUTCOME: 'outcome',
};

const DATA_ORDER = [
  COLUMN_NAMES.SALES_CHANNEL,
  COLUMN_NAMES.PRODUCT,
  COLUMN_NAMES.ENERGY_TYPE,
  COLUMN_NAMES.RUNTIME,
  COLUMN_NAMES.ENERGY_SUBTYPE,
  COLUMN_NAMES.BONUS,
  COLUMN_NAMES.BUSINESS,
  COLUMN_NAMES.CAMPAIGN,
  COLUMN_NAMES.PRICE_TYPE,
  COLUMN_NAMES.CONSUMPTION_RANGE,
  COLUMN_NAMES.CONTRACTS,
  COLUMN_NAMES.MARGIN,
  COLUMN_NAMES.CONTRIBUTION_MARGIN,
  COLUMN_NAMES.CHURN,
  COLUMN_NAMES.INCREASE_RULE,
  COLUMN_NAMES.CHURN_MODEL,
  COLUMN_NAMES.OUTCOME,
];

const DATA_LABELS_MAPPING = {
  [COLUMN_NAMES.PRODUCT]: 'Product',
  [COLUMN_NAMES.RUNTIME]: 'Initial duration',
  [COLUMN_NAMES.SALES_CHANNEL]: 'Channel',
  [COLUMN_NAMES.ENERGY_TYPE]: 'Energy',
  [COLUMN_NAMES.ENERGY_SUBTYPE]: 'Energy Subtype',
  [COLUMN_NAMES.BONUS]: 'Bonus',
  [COLUMN_NAMES.BUSINESS]: 'Business',
  [COLUMN_NAMES.CAMPAIGN]: 'Campaign',
  [COLUMN_NAMES.PRICE_TYPE]: 'Price Type',
  [COLUMN_NAMES.CONSUMPTION_RANGE]: 'Consumption Range',
  [COLUMN_NAMES.CONTRACTS]: 'Contracts',
  [COLUMN_NAMES.MARGIN]: 'Margin GM2',
  [COLUMN_NAMES.CONTRIBUTION_MARGIN]: 'GM2',
  [COLUMN_NAMES.CHURN]: 'Churn',
  [COLUMN_NAMES.INCREASE_RULE]: 'Increase Rule',
  [COLUMN_NAMES.CHURN_MODEL]: 'Churn profile',
  [COLUMN_NAMES.OUTCOME]: 'Outcome',
};

const DEFAULTS = {
  PAGE: 0,
  ROWS_PER_PAGE: ROWS_PER_PAGE_OPTIONS[1],
  SORT_VALUE: 'desc', // TODO: Use SORT_VALUES from constants [L]
  ITEMS: [],
};

export default function PriceAdjustmentsTable({
  data, options, onItemRuleChange, onChurnModelChange, hiddenColumns,
}) {
  const { t } = useTranslation();
  const { items = [] } = data;

  const [sortValue] = useState(DEFAULTS.SORT_VALUE); // TODO: Implement later
  const [page, setPage] = useState(DEFAULTS.PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULTS.ROWS_PER_PAGE);

  // TODO: useCallback?
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // TODO: useMemo?
  const sortedData = applySort(items, sortValue);
  // TODO: useMemo?
  const paginatedData = applyPagination(sortedData, page, rowsPerPage);

  // console.log('PriceAdjustmentsTable render', {
  //   sortedData,
  //   paginatedData,
  //   items,
  //   sortValue,
  //   page,
  //   rowsPerPage,
  //   options,
  //   DATA_ORDER,
  // }); // TODO: Remove later [L]

  // TODO: useMemo?
  const renderTooltip = (text, t) => (
    <Tooltip title={t(text)}>
      <InformationCircleOutlinedIcon sx={{ color: 'brandColors.lightGrey' }} />
    </Tooltip>
  );

  // TODO: useMemo?
  const renderAvatar = (item) => (item.salesChannelName === SALES_CHANNEL_NAMES.VERIVOX || item.salesChannelName === SALES_CHANNEL_NAMES.CHECK24 ? (
    <Avatar
      src={`/static/sales-channels/${item.salesChannelName}-logo.png`}
      variant="rounded"
      sx={{ width: 42, height: 42, bgcolor: 'primary.contrastText' }}
    />
  ) : (
    <Avatar {...Utils.stringAndBgColorAvatar(item.salesChannelName)} />
  ));

  // TODO: useMemo?
  const renderSalesChannelCell = (item) => (
    <TableCell key={COLUMN_NAMES.SALES_CHANNEL}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {renderAvatar(item)}
        <Typography sx={{ ml: 1 }} variant="subtitle2">
          {t(item.salesChannelName)}
        </Typography>
      </Box>
    </TableCell>
  );
  const renderTextCell = (item, column) => (
    <TableCell key={column}>
      <Box>
        <Typography variant="subtitle2">{t(item[column])}</Typography>
      </Box>
    </TableCell>
  );
  // TODO: useMemo?
  const renderProductCell = (item) => (
    <TableCell key={COLUMN_NAMES.PRODUCT}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {item.energyTypeName === 1 && <FlashOnIcon sx={{ width: 42, height: 42 }} />}
        {item.energyTypeName === 2 && <LocalFireDepartmentIcon sx={{ width: 42, height: 42 }} />}

        <Box sx={{ ml: 0 }}>
          <Typography color="inherit" variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {item.productName}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  );
  // TODO: useMemo?
  const renderEnergyTypeCell = (item) => (
    /* Energy type (Electricity or gas) */
    <TableCell key={COLUMN_NAMES.ENERGY_TYPE}>
      <Box>
        {item.energyTypeName === 1 && <FlashOnIcon sx={{ width: 42, height: 42 }} />}
        {item.energyTypeName === 2 && <LocalFireDepartmentIcon sx={{ width: 42, height: 42 }} />}
        {![1, 2].includes(item.energyTypeName) && (
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            {item.energyTypeName}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
  // TODO: useMemo?
  const renderRuntimeCell = (item) => (
    <TableCell key={COLUMN_NAMES.RUNTIME}>
      <Box>
        <Typography color="textSecondary" variant="body2">
          {`${item.runtime} months`}
        </Typography>
      </Box>
    </TableCell>
  );
  // TODO: useMemo?
  const renderContractsCell = (item) => (
    <TableCell key={COLUMN_NAMES.CONTRACTS}>
      {numeral(item.contractsCount).format('0,0')}
      <LinearProgress value={item.contractsProgress} variant="determinate" />
    </TableCell>
  );
  // TODO: useMemo?
  const renderMarginCell = (item) => (
    /* Margin Year 1 */
    <TableCell key={COLUMN_NAMES.MARGIN}>
      <SeverityPill color={Utils.getColorByMargin(item.marginYr1)}>
        {`${numeral(item.marginYr1).format('0,0.0')} %`}
      </SeverityPill>
    </TableCell>
  );
  // TODO: useMemo?
  const renderContributionMarginCell = (item) => (
    /* Contribution margin 1 */
    <TableCell key={COLUMN_NAMES.CONTRIBUTION_MARGIN}>
      {`€ ${numeral(item.contributionMarginYr1).format('0,0')}`}
      <LinearProgress
        value={item.contributionMarginYr1Progress}
        variant="determinate"
        color={Utils.getColorByMargin(item.contributionMarginYr1)}
      />
    </TableCell>
  );
  // TODO: useMemo?
  const renderChurnCell = (item) => (
    /* Initial churn */
    <TableCell key={COLUMN_NAMES.CHURN}>
      <SeverityPill color={Utils.getColorByChurn(item.churnInitial)}>
        {`${numeral(item.churnInitial).format('0,0')} %`}
      </SeverityPill>
    </TableCell>
  );
  // TODO: useMemo?
  const renderIncreaseRuleCell = (item) => (
    <TableCell key={COLUMN_NAMES.INCREASE_RULE}>
      <Dropdown
        fullWidth
        name={COLUMN_NAMES.INCREASE_RULE}
        onChange={(e) => onItemRuleChange(e, item)}
        options={options.ruleOptions}
        value={item.increaseRule}
      />
    </TableCell>
  );
  // TODO: useMemo?
  const renderChurnModelCell = (item) => (
    <TableCell key={COLUMN_NAMES.CHURN_MODEL}>
      <Dropdown
        fullWidth
        name={COLUMN_NAMES.CHURN_MODEL}
        onChange={(e) => onChurnModelChange(e, item)}
        options={options.churnModelsOptions}
        value={item.churnModelId}
      />
    </TableCell>
  );

  // TODO: useMemo?
  const renderProgress = (item) => (
    <Box
      sx={{
        display: 'flex',
        marginBottom: '0.5em',
      }}
    >
      <Loader sx={{ width: '20px', mr: 1 }} />
      <Typography variant="body3">{`${t('Processing')} ${item.processedContracts}/${item.contractsCount}`}</Typography>
    </Box>
  );

  // TODO: useMemo?
  const renderOutcomeCell = (item) => (
    <TableCell key={COLUMN_NAMES.OUTCOME}>
      <Box sx={{ minWidth: '200px' }}>
        {item.isProcessing ? renderProgress(item) : null}

        <Box sx={{ display: 'flex' }}>
          <Typography color="inherit" variant="subtitle2" sx={{ width: '70px', lineHeight: '1.5rem' }}>
            {t('Margin')}
          </Typography>
          <Chip
            label={`${numeral(item.newMarginRel).format('0.0')} %`}
            size="small"
            sx={{ width: '6em' }}
            color={Utils.getColorByMargin(item.newMarginRel)}
          />
        </Box>

        <Box sx={{ display: 'flex', mt: 0.5 }}>
          <Typography color="inherit" variant="subtitle2" sx={{ width: '70px', lineHeight: '1.5rem' }}>
            {t('Increase')}
          </Typography>
          <Chip
            label={`${numeral(item.newIncrease).format('0.0')} %`}
            size="small"
            sx={{ width: '6em' }}
            color={Utils.getColorByIncrease(item.newIncrease)}
          />
        </Box>
      </Box>
    </TableCell>
  );

  // TODO: useMemo?
  const renderCell = (column, item) => {
    switch (column) {
      case COLUMN_NAMES.SALES_CHANNEL:
        return renderSalesChannelCell(item);
      case COLUMN_NAMES.PRODUCT:
        return renderProductCell(item);
      case COLUMN_NAMES.ENERGY_TYPE:
        return renderEnergyTypeCell(item);
      case COLUMN_NAMES.RUNTIME:
        return renderRuntimeCell(item);
      case COLUMN_NAMES.CONTRACTS:
        return renderContractsCell(item);
      case COLUMN_NAMES.MARGIN:
        return renderMarginCell(item);
      case COLUMN_NAMES.CONTRIBUTION_MARGIN:
        return renderContributionMarginCell(item);
      case COLUMN_NAMES.CHURN:
        return renderChurnCell(item);
      case COLUMN_NAMES.INCREASE_RULE:
        return renderIncreaseRuleCell(item);
      case COLUMN_NAMES.CHURN_MODEL:
        return renderChurnModelCell(item);
      case COLUMN_NAMES.OUTCOME:
        return renderOutcomeCell(item);
      case COLUMN_NAMES.ENERGY_SUBTYPE:
      case COLUMN_NAMES.BONUS:
      case COLUMN_NAMES.BUSINESS:
      case COLUMN_NAMES.CAMPAIGN:
      case COLUMN_NAMES.PRICE_TYPE:
      case COLUMN_NAMES.CONSUMPTION_RANGE:
        return renderTextCell(item, column);
      default:
        return null;
    }
  };

  // TODO: useMemo?
  const renderHeaders = () => (
    <TableHead>
      <TableRow>
        {DATA_ORDER.map((columnName) => {
          if (hiddenColumns.includes(columnName)) {
            return null;
          }

          return (
            <TableCell key={columnName} sortDirection={sortValue}>
              <TableSortLabel active direction={sortValue}>
                <span>{t(DATA_LABELS_MAPPING[columnName])}</span>

                {DESCRIPTION[columnName] ? renderTooltip(DESCRIPTION[columnName], t) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );

  // TODO: useMemo?
  const renderTbody = () => (
    <TableBody>
      {paginatedData.map((item) => (
        <TableRow hover key={item.id}>
          {DATA_ORDER.map((columnName) => {
            if (hiddenColumns.includes(columnName)) {
              return null;
            }
            return renderCell(columnName, item);
          })}
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <div>
      <Scrollbar
        sx={{
          '.simplebar-content-wrapper': {
            borderRadius: '16px',
          },
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          {renderHeaders()}
          {renderTbody()}
        </Table>
      </Scrollbar>

      <TablePagination
        component="div"
        count={sortedData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      />
    </div>
  );
}

PriceAdjustmentsTable.COLUMN_NAMES = COLUMN_NAMES;

PriceAdjustmentsTable.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  options: PropTypes.shape({
    ruleOptions: PropTypes.arrayOf(PropTypes.shape({})),
    churnModelsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onItemRuleChange: PropTypes.func,
  onChurnModelChange: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

PriceAdjustmentsTable.defaultProps = {
  data: {},
  options: {},
  hiddenColumns: [],
  onItemRuleChange: (f) => f,
  onChurnModelChange: (f) => f,
};
