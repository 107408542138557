// TODO: Improve, rename and move to utils and reuse? Cover by tests [L]
const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

// TODO: Improve, rename and move to utils and reuse? Cover by tests [L]
export const getComparator = (sortDir, sortBy) => (sortDir === 'desc' ? (a, b) => -descendingComparator(a, b, sortBy) : (a, b) => descendingComparator(a, b, sortBy));
