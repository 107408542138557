// https://www.npmjs.com/package/country-list
import countryList from 'country-list';

// eslint-disable-next-line import/no-cycle
import * as Adapters from '../adapters';

export function getCountryDataList() {
  return countryList.getData();
}

export function getCountryNameByCode(code = '') {
  if (!code) {
    return '';
  }

  try {
    return countryList.getName(code);
  } catch (error) {
    console.error(error);
    return '';
  }
}

// TODO: Move to nextjs/api? [L]
export function getCountryOptionsList() {
  return new Promise((resolve) => {
    const data = countryList.getData();
    const options = Adapters.countryListDataToOptions(data);
    // TODO: Add memory cache
    resolve(options);
  });
}
