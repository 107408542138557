import PropTypes from 'prop-types';
import Image from 'next/image';
import { styled } from '@mui/material/styles';

// TODO: Improve and refactoring this file. Rename this file to Logo.js [L]
export const Logo = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { variant, slim, ...other } = props;
  return (
    <Image
      src={slim ? '/static/enneo-logo-box.svg' : '/static/enneo-logo.svg'}
      alt="Enneo logo"
      width={642}
      height={156}
    />
  );
})``;

Logo.defaultProps = {
  variant: 'primary',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
};
