/* eslint-disable react/jsx-no-duplicate-props */

import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

export default function TextInput({
  error,
  fullWidth,
  helperText,
  label,
  margin,
  name,
  onBlur,
  onChange,
  value,
  type,
  autoFocus,
  required,
  multiline,
  rows,
  inputProps,
  InputProps,
  defaultValue,
  placeholder,
  disabled,
  size,
  onKeyDown,
  sx,
  inputRef,
}) {
  return (
    <TextField
      placeholder={placeholder}
      rows={rows}
      defaultValue={defaultValue}
      multiline={multiline}
      required={required}
      autoFocus={autoFocus}
      error={error}
      inputProps={inputProps}
      InputProps={InputProps}
      fullWidth={fullWidth}
      helperText={helperText}
      label={label}
      disabled={disabled}
      margin={margin}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      type={type}
      size={size}
      onKeyDown={onKeyDown}
      sx={sx}
      inputRef={inputRef}
    />
  );
}

TextInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
};

TextInput.defaultProps = {
  error: undefined,
  label: undefined,
  helperText: undefined,
  type: undefined,
  margin: undefined,
  value: undefined,
  fullWidth: undefined,
  autoFocus: undefined,
  required: undefined,
  multiline: undefined,
  rows: undefined,
  disabled: false,
  inputProps: undefined,
  InputProps: undefined,
  defaultValue: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  size: undefined,
  onKeyDown: undefined,
};
