import Axios from 'axios';
import { t } from 'i18next';

import { BACKEND_API, HTTP_CODES } from '../../constants';
import { isTestEnv } from '../system/env';
import { redirectToLoginPage } from '../helpers/location';

export const AUTH_PATH = '/api/auth/v1';
export const PRICES_PATH = '/api/prices';

export const API = {
  LOCAL_LOGIN: '/local/login',
  LOCAL_REGISTRATION: '/local/registration',
  LOCAL_PASSWORD: '/local/password',
  LOGOUT: '/logout',
  SESSION: '/session',
  PROFILE: '/profile',
  CLIENT_CONFIGS: '/settings',
  RULES_MAPPING: '/rules/mapping',
};

const axiosClient = Axios.create({ withCredentials: true }); // TODO: Is it right withCredentials way? [L]

axiosClient.defaults.baseURL = BACKEND_API;

// TODO: Cover by tests [L]

// Use it when necessary
// axiosClient.defaults.headers = {};
// axiosClient.defaults.timeout = 2000;

axiosClient.interceptors.response.use(null, (error) => {
  const { response: { status, config: { url = '' } = {} } = {} } = error;

  const notAuthRequest = url.includes(API.LOCAL_LOGIN) || url.includes(API.LOCAL_REGISTRATION) || url.includes(API.SESSION);
  const shouldRedirectToLoginPage = status === HTTP_CODES.UNAUTHORIZED && notAuthRequest;

  if (shouldRedirectToLoginPage) {
    redirectToLoginPage();
    return;
  }

  throw error;
});

export function handleError(error = {}) {
  const headers = error.response?.headers || {};
  const status = error.response?.status || error.response?.statusCode || 500;
  const errorDetails = error.response?.data || {};
  const errorMessage = errorDetails.error || error.message;
  const { requestId, identifier } = errorDetails;

  const errorInfo = {
    ...errorDetails,
    headers,
    status,
    message: t(errorMessage),
    errorMessage,
    error,
    identifier,
    requestId,
  };

  if (!isTestEnv()) {
    console.info('Custom error', errorInfo);
  }

  return errorInfo;
}

export async function performCSR({ showLoader = () => {}, setErrorsState = () => {} }, apiMethod, apiParams = []) {
  try {
    showLoader(true);
    const data = await apiMethod(...apiParams);
    showLoader(false);
    setErrorsState({ [apiMethod.name]: undefined });

    return { data };
  } catch (error) {
    showLoader(false);
    setErrorsState({ [apiMethod.name]: error });

    return { error };
  }
}

export default axiosClient;
