import Head from 'next/head';
import Router from 'next/router';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import nProgress from 'nprogress';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import '../i18n';
import '../styles/fonts.css';
import '../styles/globals.css';

import { RTL } from '../components/RTL';
import { SettingsButton } from '../components/SettingsButton';
import SplashScreen from '../components/SplashScreen';
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context';
import { AuthConsumer, AuthProvider } from '../contexts/authContext';
import { store } from '../store';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { PAGE_TITLE } from '../constants';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

// TODO: Improve eslint + prettier config [L]
// TODO: Fix eslint warnings and update the rules from warn to error in eslint config. Remove eslint-disable from files [L]
// TODO: clean public folder [L]

console.info('System info:', {
  PREFILL_LOGIN_CREDENTIALS: process.env.PREFILL_LOGIN_CREDENTIALS,
  NODE_ENV: process.env.NODE_ENV,
});


// TODO: Add PropTypes [L]
function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{PAGE_TITLE}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider
                    theme={createTheme({
                      direction: settings.direction,
                      responsiveFontSizes: settings.responsiveFontSizes,
                      mode: settings.theme,
                    })}
                  >
                    <RTL direction={settings.direction}>
                      <CssBaseline />
                      <Toaster
                        position="top-center"
                        toastOptions={{
                          duration: 10000,
                          success: {
                            duration: 3000,
                          },
                        }}
                      />
                      <SettingsButton />
                      <AuthConsumer>
                        {(auth) => (!auth.isInitialized ? <SplashScreen /> : getLayout(<Component {...pageProps} />))}
                      </AuthConsumer>
                    </RTL>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}

export default App;
