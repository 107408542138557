import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import { LogoBox } from './LogoBox';

const bounce1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 1px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const bounce3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 3px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

// TODO: Remove this component (update to PageLoader)
// TODO: The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type". [L]
export default function Loader({ sx }) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <LogoBox
        sx={{
          height: '100%',
          width: '100%',
          '& path:nth-child(1)': {
            animation: `${bounce1} 1s ease-in-out infinite`,
          },
          '& path:nth-child(3)': {
            animation: `${bounce3} 1s ease-in-out infinite`,
          },
        }}
      />
    </Box>
  );
}

Loader.propTypes = {
  sx: PropTypes.shape({}),
};

Loader.defaultProps = {
  sx: {},
};
