import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Contract performance
      description_margin2:
        '(Total revenue of customer excl. bonus) / (Energy purchasing + Grid + Governmental components/fees + Taxes + Cost-to-Serve).',
      description_contributionMargin2:
        '((Total revenue of customer excl. bonus) - (Energy purchasing + Grid + Governmental components/fees + Taxes + Cost-to-Serve)) / Number of Contracts. Timeframe is last 12 months or start of delivery, whatever is later.',
      description_churnInitial:
        'Churn of this cohort after initial contract duration. Cohort = Contracts of the same sales channel and the same product.',
      description_dontIncrease:
        'Do not increase the price of the customer. This includes not passing on any grid/EEG changes.',
      description_setTargetMargin: 'Set target margin on top of current costs.',
      description_setTargetMarginAtFixedBasePrice: 'Set overall target margin on top of current costs, but leave the base price as it is.',

      // Rules page
      contractChartModalSubline: 'Chart data shows Net values. Hover to see Gross values and other details.',

      // Contract Details
      basePriceMargin: 'Margin on base price',
      workingPriceMargin: 'Margin on working price',
      workingPriceMarginYearAbs: 'Margin on working price',
      maxWorkingPriceMarginAbsGross: 'Maximum working price',
      neverIncreaseAboveMarginAbs: 'Limit for Price adjustment',
    },
  },
  de: {
    translation: {
      // Login page
      'Log in': 'Einloggen',
      'Sign in to enneo': 'Anmelden bei enneo',
      'This is a development release': 'Dies ist eine Entwicklungsversion',
      'Demo credentials have been pre-filled': 'Demo-Anmeldeinformationen wurden bereits ausgefüllt',
      Or: 'Oder',
      Email: 'E-Mail',
      Password: 'Passwort',

      // Menu items
      Overview: 'Überblick',
      Assign: 'Regeln zuordnen',
      'Contract Performance': 'Übersicht',
      'Define rules': 'Regeln definieren',
      'Wholesale prices': 'Einkauf',
      Assumptions: 'Annahmen',
      'Churn profiles': 'Churn Profile',
      Configuration: 'Einstellungen',
      'Price Adjustments': 'Preisanpassung',
      Rules: 'Regeln definieren',
      Execute: 'Ausführen',
      'Output Management': 'Output-Management',
      'E-mails': 'E-mails',
      Documents: 'Dokumente',
      Brands: 'Marken',
      'Assign E-Mail to Event': 'E-Mail/Event Zuordnung',
      Log: 'Log',
      Settings: 'Einstellungen',
      Home: 'Home',
      'Language changed': 'Sprache geändert',
      'Your tier': 'Ihre Stufe',
      General: 'Allgemein',
      'Rules mapping': 'Regeln zuweisen',
      'Ticket Assignment': 'Ticket klassifizieren',

      // Pages
      'Configure prices adjustment rule': 'Preisanpassungsregel ändern',
      'Assign adjustment rules to contracts': 'Zuordnen von Anpassungsregeln zu Verträgen',

      // Homepage
      'New contracts (24h)': 'Neue Verträge (24h)',
      'Supplied contracts': 'Belieferte Verträge',
      'Supplied and pending contracts': 'Belieferte Verträge und Verträge im Wechselprozess',
      'more then last month': 'mehr als im letzten Monat',
      'Top Products Last 30 days': 'Top-Produkte der letzten 30 Tage',
      Contracts: 'Verträge',
      'Quality of electricity customers': 'Neukundenqualität Strom',
      'Quality of gas customers': 'Neukundenqualität Gas',
      'Sales channel breakdown': 'Aufschlüsselung der Vertriebskanäle',
      'Product Breakdown': 'Aufschlüsselung der Produkte',
      'Sales Channel': 'Vertriebskanal',
      EBIT: 'EBIT',

      // Price adjustments
      'Margin GM2': 'Marge DB2',
      GM2: 'DB2',
      Churn: 'Churn (WIP!)',
      'Increase Rule': 'Regel',
      Outcome: 'Ergebnis',
      description_margin2:
        '(Gesamtumsatz des Kunden ohne Bonus) / (Energieeinkauf + Netz + staatliche Komponenten/Gebühren + Steuern + Cost-to-Serve).',
      description_contributionMargin2:
        '((Gesamtumsatz des Kunden ohne Bonus) - (Energieeinkauf + Netz + staatliche Komponenten/Gebühren + Steuern + Cost-to-Serve)) / Anzahl von Verträgen. Zeitrahmen sind die letzten 12 Monate oder der Beginn der Lieferung, je nachdem, was später eintritt.',
      description_churnInitial:
        'Churn dieser Kohorte nach der ursprünglichen Vertragsdauer. Kohorte = Verträge desselben Vertriebskanals und desselben Produkts.',
      'Initial duration': 'Anfängliche Dauer',

      // Increase Rule
      'Never increase': 'Niemals erhöhen',
      'Profitable in 2 years': 'Profitabel in 2 Jahren',
      'Profitable in 3 years': 'Profitabel in 3 Jahren',
      'Increase 5-15%': 'Erhöhung 5-15%',
      'Rule-based': 'Rule-based',
      'Reach break-even': 'Reach break-even',
      "Don't increase": "Don't increase",
      'Target Margin': 'Target Margin',
      'Target Margin at Fixed Base Price': 'Zielmarge zum Festpreis',
      'Current Market futures': 'Current Market futures',
      'Effective price with hedges': 'Effective price with hedges',

      // Rules Page
      'Selected rule': 'Ausgewählte Regel',
      'Configure rules': 'Regeln konfigurieren',
      'Increase logic': 'Methode',
      'Wholesale price assumption': 'Annahme des Großhandelspreises',
      'Sneak peek of result': 'Vorschau der Anpassung',
      'Custom selection': 'Filtern nach',
      'Rules for external components': 'Regeln für externe Komponenten',
      "Don't change prices if margin is above": 'Preise nicht ändern, wenn die gesamte Vertragsmarge über',
      'Pass on EEG': 'EEG weitergeben',
      'Pass on grid changes': 'Netzentgeltänderungen weitergeben',
      'Pass on other components (AblaV, KWK, ...)':
        'Andere staatliche und gesetzliche Komponenten (AblaV, KWK, ...) weitergeben',
      'only when increased': 'nur bei Erhöhung', // TODO: not working that way [L]
      'only when increased2': 'nur bei Erhöhung 2', // TODO: not working that way [L]
      'Rules for energy price': 'Regeln für den Energiepreis',
      Draft: 'Entwurf',
      Calculating: 'Berechne',
      'then increase by': 'erhöhen sich dann um',
      'Break even configuration': 'Break-even Einstellung',
      'Target year to reach break even:': 'Break-Even Jahr:',
      'Consider Bonus': 'Bonus berücksichtigen',
      'Consider Cost-to-Serve': 'Cost-to-Serve berücksichtigen',
      'Consider Cost-to-Aquire': 'Aquisekosten berücksichtigen',
      'Recover losses of churned customers': 'Churnverluste ausgleichen',
      Options: 'Optionen',
      'Base price margin': 'Margenaufschlag auf fixe Kosten',
      'Overall fixed margin': 'Feste Gesamtmarge',
      'Working price margin': 'Margenaufschlag auf variable Kosten',
      'Overall variable margin': 'Variable Gesamtmarge',
      'Price effect (gross)': 'Preisauswirkung (brutto)',
      'GP/month': 'GP/Monat',
      'Total/year': 'Jahressumme',
      'Margin effect (net)': 'Margenauswirkung (netto)',
      'Churn prediction': 'Churnerwartung',
      Total: 'Insgesamt',
      net: 'Netto',
      Contract: 'Vertrag',
      Revenue: 'Umsatz',
      last: 'letzten',
      'Direct Costs': 'Direkte Kosten',
      'Gross Margin': 'Bruttomarge',
      description_dontIncrease:
        'Erhöhen Sie nicht den Preis für den Kunden. Dazu gehört auch, dass keine Netz-/EEG-Änderungen weitergegeben werden.',
      description_setTargetMargin: 'Legt die Zielmarge als Aufschlag auf die eigenen Kosten fest.',
      description_setTargetMarginAtFixedBasePrice: 'Legt die Gesamtzielmarge zusätzlich zu den laufenden Kosten fest, aber lasst den Basispreis unverändert.',
      'Restore this version': 'Diese Version wiederherstellen',
      'Close History': 'Versionshistorie schließen',
      History: 'Versionshistorie',
      'Variable costs (ct/kWh)': 'Variable Kosten (ct/kWh)',
      'Fixed costs (€/yr)': 'Fixe Kosten (€/Jahr)',
      'Total ': 'Summe ',
      Component: 'Kostenart',
      Net: 'Netto',
      Gross: 'Brutto',
      gross: 'Brutto',
      contractChartModalSubline: 'Alle Werte Netto. Weitere Details sichtbar bei Mouse-Over.',
      year: 'Jahr',
      'Show version history': 'Versionshistorie',
      'Margin options': 'Margenvorgaben',
      'Rule Selection': 'Regelauswahl',
      'General settings': 'Grundeinstellungen',
      contracts: 'Verträge',
      always: 'immer',
      Yes: 'Ja',
      No: 'Nein',
      Delete: 'Löschen',
      Details: 'Details',
      Processing: 'Verarbeitung',
      'Year 1': 'Jahr 1',
      'Year 2': 'Jahr 2',
      'Year 3': 'Jahr 3',
      'Year 4': 'Jahr 4',
      'Year 5': 'Jahr 5',
      Name: 'Name',
      Create: 'Erstellen',
      Close: 'Schließen',
      'Create new rule': 'Neue Regel erstellen',
      'Max working price margin': 'Arbeitspreisbegrenzung',
      'Max working price': 'Maximaler Arbeitspreis',

      // Review price increases
      'Review price increases': 'Preisanpassung ausführen',
      Actions: 'Aktionen',
      'to selected contracts': 'zu ausgewählten Verträgen',
      Accept: 'Akzeptieren',
      Decline: 'Rückgang',

      // Wholesale prices
      'Set prices': 'Preise angeben',
      'Hedging ratio': 'Hedging-Quote',
      'Energy price': 'Energiepreis',
      'Market conditions': 'Aktuelle Marktpreise',
      'Company hedges': 'Bestehende Hedges',
      'Effective price': 'Realisierter Preis',
      Historic: 'Ausgeführt',
      'Standard SLP': 'Standard SLP',
      'HT/NT': 'HT/NT',
      Business: 'Gewerbe',
      January: 'Januar',
      February: 'Februar',
      March: 'März',
      April: 'April',
      May: 'Mai',
      June: 'Juni',
      July: 'Juli',
      August: 'August',
      September: 'September',
      October: 'Oktober',
      November: 'November',
      December: 'Dezember',
      Month: 'Monat',
      Type: 'Typ',
      'Energy Type': 'Energieart',
      'Load Profile': 'Lastprofil',
      Price: 'Preis',

      // Profile
      'Profile Details': 'Profil-Details',
      'Edit profile': 'Profil bearbeiten',
      'KYC Level': 'KYC-Stufe',
      'First Name': 'Vorname',
      'Last Name': 'Nachname',
      Gender: 'Geschlecht',
      'Email address': 'E-Mail Adresse',
      Country: 'Land',
      Address: 'Adresse',
      Language: 'Sprache',
      'Reset Password': 'Passwort zurücksetzen',
      'Data Management': 'Datenverwaltung',
      'Delete Account': 'Konto löschen',

      // Churn profiles
      'Select churn profile': 'Abwanderungsprofil auswählen',
      'Churn prediction matrix': 'Abwanderungsvorhersage-Matrix',
      'Verivox/C24 Bonus': 'Verivox/C24-Bonus',
      'Verivox/C24 No-Bonus': 'Verivox/C24 No-Bonus',
      'Own Website': 'Eigene Website',
      'Offline One-time commission': 'Offline Einmalige Provision',
      'Offline Recurring commission': 'Offline Wiederkehrende Provision',
      'Price increase': 'Preiserhöhung',
      'Competition price': 'Wettbewerbspreis',
      'Reference Prices (X-Axis)': 'Referenzpreise (X-Achse):',
      'Price difference in €/month to cheapest verivox tariff':
        'Preisunterschied in €/Monat zum günstigsten verivox-Tarif',
      'Price Increase (Y-Axis)': 'Preissteigerung (Y-Achse):',
      'Relative price increase to customer (excl. effect of any bonus)':
        'Relative Preiserhöhung für den Kunden (ohne Auswirkung eines etwaigen Bonus)',
      'Churn factors': 'Abwanderungsfaktoren',
      'Source of data model': 'Quelle des Datenmodells',
      'Price increases effective Jan 2021 - Oct 2021': 'Preiserhöhungen ab Januar 2021 - Oktober 2021',
      Bonus: 'Bonus',
      'Including one-time bonus for customer': 'Inklusive einmaligem Bonus für Kunden',
      'Sales Channel:': 'Sales Channel',
      'Price Comparision site': 'Preisvergleichs-Website',
      Loyalty: 'Loyalität',
      'Customers of all tenures considered': 'Kunden aller Laufzeiten berücksichtigt',
      'Dual-Fuel': 'Dual-Fuel',
      'Both single and dual fuel customers': 'Strom und Gaskunden',
      'All consumption levels considered': 'Alle Verbräuche berücksichtigt',

      // Features
      'Expected churn': 'Erwarteter churn',
      'Current prices': 'Aktuelle preise',
      'New prices': 'Neue preise',
      'Failure reason': 'Fehlergrund',
      'PC-Transfer/ Effective': 'PC-Transfer/ Wirksam',

      // Misc
      Year: 'Jahr',
      Years: 'Jahre',
      'Group by': 'Gruppiern nach',
      'Filter by': 'Filtern nach',
      Criteria: 'Kriterien',
      Product: 'Produkt',
      Energy: 'Energie',
      'Contract duration': 'Vertragsdauer',
      'Energy Subtype': 'Wärmetarife',
      Campaign: 'Kampagne',
      'Consumption range': 'Verbrauch',
      'Price Type': 'Zählwerke',
      'Sales channel': 'Kanal',
      Channel: 'Kanal',
      'Increase Date': 'Anpassungsdatum',
      Margin: 'Marge',
      Consumption: 'Verbrauch',
      'Increase in next 30 days': 'Anpassung in den nächsten 30 Tagen',
      'Increase in next 12 months': 'Anpassung in den nächsten 12 Monaten',
      'All customers': 'Alle Kunden',
      'Offline own': 'Offline eigene Kunden',
      'Offline partners': 'Offline-Partner',
      Online: 'Online',
      'Online Partners': 'Online-Partner',
      Increase: 'Erhöhung',
      Period: 'Zeitraum',
      Notifications: 'Benachrichtigungen',
      'There are no notifications': 'Es gibt keine Benachrichtigungen',
      Hot: 'Hot',
      month: 'Monat',

      // execute page
      'Do you really want to approve all price adjustments?': 'Wollen Sie wirklich alle Preisanpassungen genehmigen?',
      'Confirmation modal': 'Modal für Bestätigungen',
      Confirm: 'Bestätigen',
      'waiting for pending calculations': 'warten auf ausstehende Berechnungen',
      Retry: 'Wiederholen',

      // Rules assign page
      'Add condition': 'Neue Kriterien',
      'Add set': 'Neue Regel',
      'Apply to all contracts': 'Regeln auf alle Verträge anwenden',

      // Buttons
      Update: 'Update',
      Cancel: 'Abbrechen',
      Save: 'Speichern',
      Add: 'Hinzufügen',
      Edit: 'Bearbeiten',
      Back: 'Zurück',
      'See all': 'Alle sehen',
      Export: 'Exportieren',
      'Export current selection': 'Aktuelle Filtereinstellung',
      'Export all active contracts': 'Alle belieferte Verträge',
      'Export current selection with all data fields': ' Alle belieferte Verträge mit allen Daten',
      'Export all contracts': 'Alle Verträge',
      'Export backtest of upcoming price adjustment': 'Preisanpassung einer Kontrollgruppe',
      'Data issues': 'Datenklärfälle',

      // Contract performance
      'Contract performance': 'Übersicht',

      // Price adjustment modes
      Upcoming: 'Geplant',
      Errors: 'Fehler',
      All: 'Alle',

      // Contract chart
      Costs: 'Kosten',
      'Adjustment Revenue': 'Umsatz (Plan)',
      'Adjustment Costs': 'Kosten (Plan)',

      // Common
      Electricity: 'Strom',
      Gas: 'Gas',
      electricity: 'Strom',
      gas: 'Gas',
      Logout: 'Abmelden',
      German: 'Deutsch',
      English: 'Englisch',
      Male: 'Männlich',
      Female: 'Weiblich',
      'Non-binary': 'Divers',

      // Settings
      Profile: 'Profil',
      Security: 'Sicherheit',
      'Change password': 'Passwort ändern',
      Devices: 'Geräte',
      'Multi Factor Authentication': 'Multi-Faktor-Authentifizierung',
      Off: 'Aus',
      'Authenticator App': 'Authenticator-App',

      // Errors
      'Wrong password.': 'Falsches Passwort.',

      // Configurations
      'Update configs': 'Einstellungen aktualisieren',
      Configurations: 'Einstellungen',
      Prices: 'Preise',

      // Contract Details
      Subtotal: 'Zwischensumme',
      Economics: 'Preisbestandteile',
      'Monthly Breakdown': 'Monatliche Aufschlüsselung',
      'Private/Business': 'Privat/Gewerbe',
      Private: 'Privatkunde',
      Rule: 'Regel',
      'Energy Price Load Profile': 'Energiepreis-Lastprofil',
      'Date of Next Price Adjustment': 'Datum der nächsten Preisanpassung',
      'Date of adjustment thereafter': 'Datum der übernächsten Preisanpassung',
      'Validity of new price': 'Gültigkeit neuer Preise',
      'Fixed revenue components €/yr': 'Feste Preiskomponenten €/yr',
      'Total Fixed Costs': 'Gesamte Fixkosten',
      'Total Fixed Revenue': 'Grundpreis (jährlich)',
      'Total Fixed Revenue Monthly': 'Grundpreis (monatlich)',
      'Variable revenue components (ct/kWh)': 'Variable Preiskomponenten (ct/kWh)',
      'Total Variable Costs': 'Gesamte variable Kosten',
      'Total Variable Revenue': 'Arbeitspreis',
      'Total Variable Revenue Yearly': 'Umsatz durch Arbeitspreis',
      KA: 'Konzessionsabgabe',
      KWK: 'KWK-Abgabe',
      Netz_AP: 'Variable Netzentgelte',
      Offshore: 'Offshore-Umlage',
      StromNEV: 'Strom-Netzentgelteverordnung',
      Stromsteuer: 'Stromsteuer',
      energyPrice: 'Energie Großhandelspreis',
      certificates: 'Herkunftsnachweise',
      Netz_Messpreis: 'Messentgelt',
      creditCheck: 'Bonitätsprüfung',
      costToServe: 'Service-Kosten',
      Netz_GP: 'Feste Netzentgelte',
      basePriceMargin: 'Marge auf Grundpreis',
      workingPriceMargin: 'Marge auf Arbeitspreis (var)',
      workingPriceMarginYearAbs: 'Marge auf Arbeitspreis (fix)',
      maxWorkingPriceMarginAbsGross: 'Maximaler Arbeitspreis',
      neverIncreaseAboveMarginAbs: 'Keine Preisanpassung über',
      '€/year': '€/Jahr',
      Months: 'Monate',
      'Start Date': 'Belieferungsbeginn',
      'End Date': 'Belieferungsende',
    },
  },
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: DETECTION_OPTIONS,
  });
