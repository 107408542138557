import { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import LocalStorage from './index';

// TODO: Cover by tests [L]
function useLocalStorage(key, defaults = null) {
  const [dataLS, saveDataToLS] = useState(() => {
    const formDataFromLS = LocalStorage.getData(key);
    return formDataFromLS || defaults;
  });

  useEffect(() => LocalStorage.setData(key, dataLS), [dataLS, key]);

  return [dataLS, saveDataToLS, () => LocalStorage.clearData(key)];
}

export default useLocalStorage;
