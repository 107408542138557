import { t } from 'i18next';
import PriceAdjustmentsTable from '../components/PriceAdjustments/PriceAdjustmentsTable';

// TODO: Integrate all this data from BE and integrate with BE

/* OPTIONS */

// TODO: Move to getOptions [L]
export const LANGUAGE_OPTIONS = [
  {
    id: 'en',
    value: 'en',
    label: t('English'),
  },
  {
    id: 'de',
    value: 'de',
    label: t('German'),
  },
];

// TODO: Move to getOptions [L]
export const GENDER_OPTIONS = [
  {
    id: 'male',
    value: 'male',
    label: t('Male'),
  },
  {
    id: 'female',
    value: 'female',
    label: t('Female'),
  },
  {
    id: 'non-binary',
    value: 'non-binary',
    label: t('Non-binary'),
  },
];

// TODO: Move to getOptions [L]
export const PRICES_TYPES_OPTIONS = [
  {
    id: 'market_conditions',
    value: 'market_conditions',
    label: t('Market conditions'),
  },
  {
    id: 'client_hedges',
    value: 'client_hedges',
    label: t('Company hedges'),
  },
  {
    id: 'effective_price',
    value: 'effective_price',
    label: t('Effective price'),
  },
  {
    id: 'historic',
    value: 'historic',
    label: t('Historic'),
  },
];

// TODO: Move to getOptions [L]
export const ENERGY_TYPE_IDS = [
  {
    id: 'electricity',
    value: '1',
    label: t('Electricity'),
  },
  {
    id: 'gas',
    value: '2',
    label: t('Gas'),
  },
];

// TODO: Move to getOptions [L]
export const LOAD_PROFILES = [
  {
    id: 'default',
    value: 'default',
    label: t('Standard SLP'),
  },
  {
    id: 'ht/nt',
    value: 'ht/nt',
    label: t('HT/NT'),
  },
  {
    id: 'business',
    value: 'business',
    label: t('Business'),
  },
];

// TODO: Move to getOptions [L]
export const MONTHS_OPTIONS = [
  {
    id: '1',
    value: '1',
    label: t('January'),
  },
  {
    id: '2',
    value: '2',
    label: t('February'),
  },
  {
    id: '3',
    value: '3',
    label: t('March'),
  },
  {
    id: '4',
    value: '4',
    label: t('April'),
  },
  {
    id: '5',
    value: '5',
    label: t('May'),
  },
  {
    id: '6',
    value: '6',
    label: t('June'),
  },
  {
    id: '7',
    value: '7',
    label: t('July'),
  },
  {
    id: '8',
    value: '8',
    label: t('August'),
  },
  {
    id: '9',
    value: '9',
    label: t('September'),
  },
  {
    id: '10',
    value: '10',
    label: t('October'),
  },
  {
    id: '11',
    value: '11',
    label: t('November'),
  },
  {
    id: '12',
    value: '12',
    label: t('December'),
  },
];

export const INCREASE_LOGIC_VALUES = {
  RULE_BASED: 'ruleBased',
  REACH_BREAK_EVEN: 'reachBreakEven',
  DO_NOT_INCREASE: 'neverIncrease',
  SET_TARGET_MARGIN: 'setTargetMargin',
  SET_TARGET_MARGIN_AT_FIXED_BASE_PRICE: 'setTargetMarginAtFixedBasePrice',
};

export const INCREASE_LOGIC_OPTIONS = [
  {
    id: INCREASE_LOGIC_VALUES.RULE_BASED,
    value: INCREASE_LOGIC_VALUES.RULE_BASED,
    label: t('Rule-based'),
  },
  {
    id: INCREASE_LOGIC_VALUES.REACH_BREAK_EVEN,
    value: INCREASE_LOGIC_VALUES.REACH_BREAK_EVEN,
    label: t('Reach break-even'),
  },
  {
    id: INCREASE_LOGIC_VALUES.DO_NOT_INCREASE,
    value: INCREASE_LOGIC_VALUES.DO_NOT_INCREASE,
    label: t("Don't increase"),
  },
  {
    id: INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN,
    value: INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN,
    label: t('Target Margin'),
  },
  {
    id: INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN_AT_FIXED_BASE_PRICE,
    value: INCREASE_LOGIC_VALUES.SET_TARGET_MARGIN_AT_FIXED_BASE_PRICE,
    label: t('Target Margin at Fixed Base Price'),
  },
];

export const WHOLESALE_PRICE_ASSUMPTION_OPTIONS = [
  {
    id: 'market_conditions',
    value: 'market_conditions',
    label: t('Current Market futures'),
  },
  {
    id: 'effective_price',
    value: 'effective_price',
    label: t('Effective price with hedges'),
  },
];

export const GRID_CHANGES_OPTIONS = [
  {
    id: 'only when increased',
    value: 'whenIncreased',
    label: t('only when increased'),
  },
  {
    id: 'always',
    value: 'always',
    label: t('always'),
  },
];

export const OTHER_COMPONENTS_OPTIONS = [
  {
    id: 'only when increased',
    value: 'whenIncreased',
    label: t('only when increased'),
  },
  {
    id: 'always',
    value: 'always',
    label: t('always'),
  },
];

export const COMPARISON_OPTIONS = [
  { id: '<=', label: '<=', value: '<=' },
  { id: '>=', label: '>=', value: '>=' },
];

export const INCREASE_DATE_OPTIONS = [
  {
    id: 'Increase in next 30 months',
    key: 'priceAdjustmentPossibleAt',
    label: t('Increase in next 30 days'),
    value: 30,
  },
  {
    id: 'Increase in next 12 months',
    key: 'priceAdjustmentPossibleAt',
    label: t('Increase in next 12 months'),
    value: 365,
  },
  {
    id: 'All customers',
    key: 'priceAdjustmentPossibleAt',
    label: t('All customers'),
    value: null,
  },
];

export const CONSUMPTION_OPTIONS = [
  {
    id: '0-2000',
    key: 'consumption',
    label: '0-2000',
    value: '0-2000',
    from: 0,
    to: 2000,
    comparator: 'between',
  },
  {
    id: '2001-5000',
    key: 'consumption',
    label: '2001-5000',
    value: '2001-5000',
    from: 2001,
    to: 5000,
    comparator: 'between',
  },
  {
    id: '5001-100000',
    key: 'consumption',
    label: '5001-100000',
    value: '5001-100000',
    from: 5001,
    to: 100000,
    comparator: 'between',
  },
];

export const PRICE_TYPE_OPTIONS = [
  {
    id: 'single_price',
    label: 'single_price',
    key: 'priceType',
    value: 'single_price',
  },
  {
    id: 'multi_price',
    label: 'multi_price',
    key: 'priceType',
    value: 'multi_price',
  },
];
export const BONUS_OPTIONS = [
  {
    id: 'bonus_yes',
    label: t('Yes'),
    key: 'bonus',
    value: true,
  },
  {
    id: 'bonus_no',
    label: t('No'),
    key: 'bonus',
    value: false,
  },
];
export const BUSINESS_OPTIONS = [
  {
    id: 'business_yes',
    label: t('Yes'),
    key: 'business',
    value: true,
  },
  {
    id: 'business_no',
    label: t('No'),
    key: 'business',
    value: false,
  },
];
export const CURRENT_RULE_OPTIONS = [
  {
    id: 'currentRule_yes',
    label: t('Yes'),
    key: 'currentRule',
    value: true,
  },
];

export const CRITERIA_OPTIONS = [
  {
    id: 'Product',
    label: t('Product'),
    value: 'product',
  },
  {
    id: 'Energy',
    label: t('Energy'),
    value: 'energyType',
  },
  {
    id: 'Contract duration',
    label: t('Contract duration'),
    value: 'initialDuration',
  },
  {
    id: 'Sales Channel',
    label: t('Sales Channel'),
    value: 'salesChannel',
  },
  {
    id: 'Energy Subtype',
    label: t('Energy Subtype'),
    value: 'energySubType',
  },
  {
    id: 'Bonus',
    label: t('Bonus'),
    value: 'bonus',
  },
  {
    id: 'Campaign',
    label: t('Campaign'),
    value: 'campaign',
  },
  {
    id: 'Price Type',
    label: t('Price Type'),
    value: 'priceType',
  },
  {
    id: 'Consumption range',
    label: t('Consumption range'),
    value: 'consumptionRange',
  },
  {
    id: 'Business',
    label: t('Business'),
    value: 'business',
  },
];

const [
  productCriteria,
  energyTypeCriteria,
  contractDurationCriteria,
  salesChannelCriteria,
  energySubTypeCriteria,
  bonusCriteria,
  campaignCriteria,
  priceTypeCriteria,
  consumptionRangeCriteria,
  businessCriteria,
] = CRITERIA_OPTIONS;
// TODO: Move the mapping to constants? Think about this solution - improve and make more stable [L]
export const CRITERIA_VALUES_TO_COLUMN_NAME_MAPPING = {
  [productCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.PRODUCT,
  [energyTypeCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.ENERGY_TYPE,
  [contractDurationCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.RUNTIME,
  [salesChannelCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.SALES_CHANNEL,
  [energySubTypeCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.ENERGY_SUBTYPE,
  [bonusCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.BONUS,
  [businessCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.BUSINESS,
  [campaignCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.CAMPAIGN,
  [priceTypeCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.PRICE_TYPE,
  [consumptionRangeCriteria.value]: PriceAdjustmentsTable.COLUMN_NAMES.CONSUMPTION_RANGE,
};

/* [V1] DATA */

// TODO: Deprecated [V1]. Remove?
export const DEVICES = [
  {
    id: 'Device name 1',
    name: 'Device name 1',
    ip: 'ip 1',
    date: '20.03.2022',
  },
  {
    id: 'Device name 2',
    name: 'Device name 2',
    ip: 'ip 2',
    date: '20.03.2022',
  },
  {
    id: 'Device name 3',
    name: 'Device name 3',
    ip: 'ip 3',
    date: '20.03.2022',
  },
  {
    id: 'Device name 4',
    name: 'Device name 4',
    ip: 'ip 4',
    date: '20.03.2022',
  },
  {
    id: 'Device name 5',
    name: 'Device name 5',
    ip: 'ip 5',
    date: '20.03.2022',
  },
  {
    id: 'Device name 6',
    name: 'Device name 6',
    ip: 'ip 6',
    date: '20.03.2022',
  },
];
