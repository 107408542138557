import Request, { API, AUTH_PATH, handleError } from './request';
import * as Adapters from '../adapters';

// TODO: Cover by tests (L)

export function login(credentials) {
  const url = `${AUTH_PATH}${API.LOCAL_LOGIN}`;

  return Request.post(url, credentials)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function register(credentials) {
  const url = `${AUTH_PATH}${API.LOCAL_REGISTRATION}`;

  return Request.post(url, credentials)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function logout() {
  const url = `${AUTH_PATH}${API.LOGOUT}`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function me() {
  const url = `${AUTH_PATH}${API.SESSION}`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function getProfile() {
  const url = `${AUTH_PATH}${API.PROFILE}`;

  return Request.get(url)
    .then(({ data = {} }) => Adapters.accountProfileData(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getClientConfigs(app) {
  const url = (await import('./request'))[`${app.toUpperCase()}_PATH`] + API.CLIENT_CONFIGS;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function updateClientConfigs(app, updatedConfigs) {
  const url = (await import('./request'))[`${app.toUpperCase()}_PATH`] + API.CLIENT_CONFIGS;

  return Request.put(url, updatedConfigs[app])
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function updateAccountProfileData(accountData) {
  const url = `${AUTH_PATH}${API.PROFILE}`;

  return Request.patch(url, Adapters.accountProfileDataToBE(accountData))
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function changePassword(newPassword) {
  const url = `${AUTH_PATH}${API.LOCAL_PASSWORD}`;

  return Request.patch(url, { password: newPassword })
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function updateProfileData(formData) {
  const url = `${AUTH_PATH}${API.PROFILE}`;

  // TODO: Cover by tests [L]
  function adaptProfileDataToBE(data) {
    const adaptedData = {
      ...data,
      email: undefined,
    };

    return adaptedData;
  }

  return Request.patch(url, adaptProfileDataToBE(formData))
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
