import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, FormControlLabel, Menu, MenuItem,
} from '@mui/material';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';

// TODO: React.memo?
/**
 * PAY ATTENTION: Here as unique value it uses option id.
 * @todo: Think about this solution and how to improve this
 */
export default function MultiSelect({
  label, name, onChange, options, values = [],
}) {
  const { t } = useTranslation();
  const anchorRef = useRef(null);

  const [openMenu, setOpenMenu] = useState(false);
  // TODO: useCallback?
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  // TODO: useCallback?
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  // TODO: useCallback?
  const handleChange = (event) => {
    let newValues = [...values];
    const newValue = event.target.value;

    if (event.target.checked) {
      newValues.push(newValue);
    } else {
      newValues = newValues.filter((value) => value !== newValue);
    }

    onChange?.(newValues);
  };

  return (
    <>
      <Button
        sx={{
          height: '2rem',
          margin: 0.5,
          backgroundColor: (theme) => theme.palette.background.dropdown,
        }}
        color="inherit"
        endIcon={<ChevronDownIcon fontSize="medium" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{ style: { minWidth: 250 } }}
      >
        {options.map((option) => (
          <MenuItem key={option.label}>
            <FormControlLabel
              control={
                <Checkbox name={name} checked={values.includes(option.id)} onChange={handleChange} value={option.id} />
              }
              label={t(option.label)}
              sx={{
                flexGrow: 1,
                mr: 0,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

MultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.arrayOf(PropTypes.string),
};

MultiSelect.defaultProps = {
  name: undefined,
  label: undefined,
  onChange: (f) => f,
  options: [],
  values: [],
};
