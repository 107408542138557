import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box, Card, Chip, CardHeader, Typography, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MultiSelect from '../__common__/MultiSelect';
import DropdownMenuInput from '../__common__/DropdownMenuInput';

const GROUP_NAMES = {
  CHANNEL: 'Channel',
  PRODUCT: 'Product',
  ENERGY_SUBTYPE_ID: 'Energy Subtype',
  CAMPAIGN: 'Campaign',
  PRICE_TYPE: 'Price Type',
  BONUS: 'Bonus',
  BUSINESS: 'Business',
  CURRENT_RULE: 'Rule',
  INCREASE_DATE: 'Increase Date',
  MARGIN: 'Margin',
  CONSUMPTION: 'Consumption',
  ENERGY_TYPE: 'Energy',
  CRITERIA: 'Criteria',
  CONTRACT_ID: 'Contract',
};

const DEFAULTS = {
  ITEMS: [],
};
const FIELDS = Object.keys(DEFAULTS);

const getActiveItemsIds = (activeItems = [], groupName = '') => activeItems.filter((activeItem) => activeItem.group === groupName).map((activeItem) => activeItem.id);

/**
 * PAY ATTENTION: Here as unique value it uses option id.
 * @todo: Think about this solution and how to improve this [L]
 */
// TODO: useCallback? [L]
export default function CustomSelection({
  options,
  defaultValues,
  onSubmit,
  groups,
  values,
  title,
  isCollapsable,
  slim,
  showContractFilter,
}) {
  const { t } = useTranslation();
  const [activeItems, setActiveItems] = useState(defaultValues || DEFAULTS.ITEMS);

  const handleValueChange = (updatedValues, group) => {
    setActiveItems((items) => {
      const allOthersItems = items.filter((item) => !(item.group === group));
      const updatedItems = updatedValues.map(
        (id) => options?.[group]?.find((item) => item.id === id && item.group === group) || {},
      );
      const result = [...allOthersItems, ...updatedItems];

      onSubmit(result);
      return result;
    });
  };

  const handleDropdownMenuInputSubmit = (value, name) => {
    const newItem = {
      group: name,
      value,
      id: value,
      label: value,
      key: 'contractId',
    };
    const isExists = activeItems.find((item) => item.id === newItem.id && item.group === newItem.group);
    if (!isExists) {
      const result = [...activeItems, newItem];

      setActiveItems(result);
      onSubmit(result);
    }
  };

  const handleDeleteClick = (id, group) => {
    setActiveItems((items) => {
      const result = items.filter((item) => !(item.group === group && item.id === id));
      onSubmit(result);
      return result;
    });
  };

  useEffect(() => (values ? setActiveItems(values) : null), [values]);

  console.log('CustomSelection render', {
    title,
    options,
    defaultValues,
    values,
    activeItems,
    groups,
    slim,
  }); // TODO: Remove later [L]

  // TODO: useMemo? [L]
  const renderTags = () => (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        mt: 1,
      }}
    >
      {activeItems.map((item) => (
        <Chip
          key={item.id}
          label={(
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                '& span': {
                  fontWeight: 600,
                },
              }}
            >
              <span>{t(item.group)}</span>
              :&nbsp;
              {t(item.label) || t(item.value)}
            </Box>
          )}
          onDelete={() => handleDeleteClick(item.id, item.group)}
          sx={{ m: 0.5 }}
          variant="outlined"
        />
      ))}
    </Box>
  );

  // TODO: useMemo? [L]
  const renderMultiSelects = () => groups.map((groupName) => (
    <MultiSelect
      key={groupName}
      name={groupName}
      label={t(groupName)}
      options={options[groupName]}
      values={getActiveItemsIds(activeItems, groupName)}
      onChange={(updatedValues) => handleValueChange(updatedValues, groupName)}
    />
  ));

  const renderHeader = ({ sx = {} } = {}) => (
    <CardHeader
      sx={{ p: 1, ...sx }}
      title={(
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h6">{t(title)}</Typography>
        </Box>
      )}
    />
  );

  const renderContent = () => (
    <Card sx={{ boxShadow: 'none' }}>
      {title && !isCollapsable ? renderHeader() : null}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          mt: 1,
        }}
      >
        {renderMultiSelects()}

        {showContractFilter ? (
          <DropdownMenuInput
            key={GROUP_NAMES.CONTRACT_ID}
            name={GROUP_NAMES.CONTRACT_ID}
            label={t(GROUP_NAMES.CONTRACT_ID)}
            onSubmit={handleDropdownMenuInputSubmit}
          />
        ) : null}

        <Box sx={{ flexGrow: 1 }} />
      </Box>
    </Card>
  );

  // FIXME: Ger rid of the 2d Card component here? [L]
  return (
    <Card sx={{ p: slim ? undefined : 2, boxShadow: 'none' }}>
      {isCollapsable ? (
        <Accordion sx={{ boxShadow: 'none' }} disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ pl: 0 }}>
            {renderHeader({ sx: { p: 0, pl: 1 } })}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>{renderContent()}</AccordionDetails>
        </Accordion>
      ) : (
        renderContent()
      )}
      {activeItems.length ? renderTags() : null}
    </Card>
  );
}

CustomSelection.DEFAULTS = DEFAULTS;
CustomSelection.FIELDS = FIELDS;
CustomSelection.GROUP_NAMES = GROUP_NAMES;

CustomSelection.propTypes = {
  options: PropTypes.shape({
    componentsOptions: PropTypes.arrayOf(PropTypes.shape({})),
    channelOptions: PropTypes.arrayOf(PropTypes.shape({})),
    productOptions: PropTypes.arrayOf(PropTypes.shape({})),
    marginOptions: PropTypes.arrayOf(PropTypes.shape({})),
    consumptionOptions: PropTypes.arrayOf(PropTypes.shape({})),
    increaseDateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  groups: PropTypes.arrayOf(PropTypes.string),
  defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
  isCollapsable: PropTypes.bool,
  slim: PropTypes.bool,
  showContractFilter: PropTypes.bool, // TODO: Use groups prop to enable contract dropdown
  title: PropTypes.string,
};

CustomSelection.defaultProps = {
  groups: [],
  defaultValues: [],
  values: [],
  options: {},
  isCollapsable: false,
  showContractFilter: false,
  slim: false,
  title: '',
  onSubmit: (f) => f,
};
