import { format } from 'date-fns';

import { isBrowser } from '../system/env';
import { DATE_FORMAT } from '../../constants';

const mergeProps = (cmsProps = {}, customAppProps = {}) => ({ ...customAppProps, ...cmsProps });

function isInt(n) {
  return n % 1 === 0;
}

function getFileExtension(file) {
  const regexExtension = /[^\\]*\.(\w+)$/;
  return file.match(regexExtension)?.[1];
}

function getFileMIMEType(file) {
  const fileExtension = getFileExtension(file);
  switch (fileExtension) {
    case 'mp4':
      return 'video/mp4';
    case 'ogv':
    case 'ogg':
      return 'video/ogg';
    case 'webm':
      return 'video/webm';
    case 'webp':
      return 'image/webp';
    case 'jpg':
      return 'image/jpg';
    case 'png':
      return 'image/png';
    default:
      return undefined;
  }
}

function isTouchDevice() {
  return isBrowser() && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
}

// TODO: Cover by tests [L]
function pickByPropList(data = {}, props = []) {
  if (!data) {
    return {};
  }

  return props.reduce((result, prop) => {
    const value = data[prop];
    if (value !== undefined) {
      return { ...result, [prop]: value };
    }
    return result;
  }, {});
}

// TODO: Cover by tests [L]
// TODO: Think about the name [L]
function setValuesByPropList(data1 = {}, data2 = {}, props = []) {
  return props.reduce((result, prop) => {
    const value1 = data1[prop];
    const value2 = data2[prop];

    return {
      ...result,
      [prop]: value1 !== null && value1 !== undefined ? value1 : value2,
    };
  }, {});
}

// TODO: Cover by tests [L]
function setDefaultValues(defaults = {}, data = {}) {
  const uniqueListOfPropNames = [...new Set([...Object.keys(defaults), ...Object.keys(data)])];
  return uniqueListOfPropNames.reduce((result, prop) => {
    const value = data[prop];
    const defaultValue = defaults[prop];

    return {
      ...result,
      [prop]: value !== null && value !== undefined ? value : defaultValue,
    };
  }, {});
}

// TODO: Cover by tests [L]
function pickValuesByPropList(data = {}, props = []) {
  return props.reduce((result, prop) => {
    const value = data[prop];

    return {
      ...result,
      [prop]: value,
    };
  }, {});
}

function isNumeric(value) {
  if (typeof value !== 'string') {
    return typeof value === 'number';
  }

  return (
    // eslint-disable-next-line no-restricted-globals
    !isNaN(value) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    // eslint-disable-next-line no-restricted-globals
    && !isNaN(parseFloat(value))
  ); // ...and ensure strings of whitespace fail
}

// TODO: Improve the logic in future [L]
function correctNumber(value) {
  if (!isNumeric(value)) {
    return null;
  }
  return Number(value).toFixed(2);
}

// TODO: Cover by tests and refactor [L]
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

// TODO: Cover by tests [L]
function stringAndBgColorAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name || ''),
    },
    children: name?.[0] || '',
  };
}

// TODO: Cover by tests [L]
function getColorByMargin(margin) {
  if (Number(margin) > 3) {
    return 'success';
  }
  if (Number(margin) < -5) {
    return 'error';
  }
  return 'warning';
}

// TODO: Cover by tests [L]
function getColorByAbsMargin(margin) {
  if (Number(margin) > 30) {
    return 'success';
  }
  if (Number(margin) < -5) {
    return 'error';
  }
  return 'warning';
}

// TODO: Cover by tests [L]
function getColorByTotalRevChange(margin) {
  if (Number(margin) > 5) {
    return 'success';
  }
  if (Number(margin) < -5) {
    return 'error';
  }
  return 'warning';
}

// TODO: Cover by tests [L]
function getColorByChurn(churnInitial) {
  if (Number(churnInitial) < 30) {
    return 'success';
  }
  if (Number(churnInitial) > 50) {
    return 'error';
  }
  return 'warning';
}

function getColorNumberByChurn(churn) {
  if (Number(churn) < 30) {
    return '#14B8A6';
  }
  if (Number(churn) > 50) {
    return '#D14343';
  }
  return '#FFDC37';
}

// TODO: Cover by tests [L]
function getColorByIncrease(increase) {
  if (Number(increase) < 5) {
    return 'success';
  }
  if (Number(increase) > 20) {
    return 'error';
  }
  return 'warning';
}

// TODO: Cover by tests [L]
function getColorByRelationValue(value) {
  if (Number(value) < 0) {
    return 'success';
  }
  if (Number(value) > 0) {
    return 'error';
  }
  return 'warning';
}

function getColorByDate(/* { date, type = 'colorByDistanceToFuture' } */) {
  // TODO: Implement [L]
  return '#448811';
}

function printRegionalDate(date) {
  // TODO, change format by language [L]
  return date;
}

export const getInitials = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

// eslint-disable-next-line consistent-return
export const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      // eslint-disable-next-line no-param-reassign
      arr[i] = deepCopy(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      // eslint-disable-next-line no-param-reassign
      newObj[key] = deepCopy(obj[key]);
      return newObj;
    }, {});
  }
};

export const getValueFromInterval = (code, colorCodes) => {
  const colorCode = Object.keys(colorCodes).reduce((min, max) => (code >= min && code < max ? min : max));

  return colorCodes[colorCode];
};

const generateOptionsByValues = (values = []) => values.map((value) => ({
  id: value,
  label: value,
  value,
}));

const generateFormattedDate = ({ year, month }) => format(new Date(year, month - 1), DATE_FORMAT);

// TODO: move this files to utils/ and use this as Utils.method, not Helpers.method or think about better solution, refactor and cover by tests [L]
export default {
  getInitials,
  deepCopy,
  mergeProps,
  isInt,
  getFileMIMEType,
  isTouchDevice,
  pickByPropList,
  setValuesByPropList,
  isNumeric,
  correctNumber,
  pickValuesByPropList,
  getColorByIncrease,
  getColorByChurn,
  getColorNumberByChurn,
  getColorByMargin,
  getColorByAbsMargin,
  getColorByDate,
  getColorByTotalRevChange,
  stringAndBgColorAvatar,
  stringToColor,
  getColorByRelationValue,
  printRegionalDate,
  setDefaultValues,
  generateOptionsByValues,
  generateFormattedDate,
};
